import moment from "moment";
import i18n from "../../../lang/lang";

const isRoomInAlert = (room) => {
    if (!room || !room.admissionList.length) return false;
    if(room.sensor.isOnline === -1) return false;
    const newAdm = room.admissionList[room.admissionList.length - 1];
    if (newAdm.alert) {
        if (
            newAdm.alert.moderation.createdAt
            && newAdm.alert.moderation.trueAlert
            && !newAdm.alert.handled.createdAt
            && !newAdm.alert.handling.createdAt
            && !newAdm.alert.autoHandling.createdAt
            && newAdm.alert.alertType === 5) {
            return true;
        }
        if (newAdm.alert.moderation.createdAt
            && newAdm.alert.moderation.trueAlert
            && !newAdm.alert.handled.createdAt
            && !newAdm.alert.handling.createdAt
            && !newAdm.alert.autoHandling.createdAt
            && newAdm.alert.alertType === 4) {
            return true;
        }
    }
}

const checkActiveAlerts = (state) => {
    for (let rs of state.roomsetList) {
        if (rs.organizationLabel == state.currentOrganization)
            for (let r of rs.roomList) {
                let ra = isRoomInAlert(r);
                if (ra) {
                    state.hasAlert = true;                    
                    return;
                }
            }
    }
    state.hasAlert = false;
}

const updateRoomset = (oldRS, newRS) => {
    //console.log("update roomset " + oldRS.label);
    // if(!newRS.version || newRS.version <= oldRS.version)
    //  return;
    if (oldRS.version === newRS.optLock)
        return;
    if (oldRS.label != newRS.label) oldRS.label = newRS.label;

    if (!oldRS.location) oldRS = { level: '', building: '' }
    if (!oldRS.location.level !== newRS.location.level) oldRS.location.level = newRS.location.level;
    if (!oldRS.location.building !== newRS.location.building) oldRS.location.building = newRS.location.building;
    if (oldRS.organizationLabel !== newRS.organizationLabel) oldRS.organizationLabel = newRS.organizationLabel;
    if (oldRS.authorizedUserOIDList.join(',') !== newRS.authorizedUserOIDList.join(',')) oldRS.authorizedUserOIDList = newRS.authorizedUserOIDList;
    if (oldRS.featureBedexitEnabled !== newRS.featureBedexitEnabled) oldRS.featureBedexitEnabled = newRS.featureBedexitEnabled;
    if (oldRS.featureCloseFollowUpEnabled !== newRS.featureCloseFollowUpEnabled) oldRS.featureCloseFollowUpEnabled = newRS.featureCloseFollowUpEnabled;
    if (oldRS.featureFallEnabled !== newRS.featureFallEnabled) oldRS.featureFallEnabled = newRS.featureFallEnabled;
    if (oldRS.featureMonthlyReportEnabled !== newRS.featureMonthlyReportEnabled) oldRS.featureMonthlyReportEnabled = newRS.featureMonthlyReportEnabled;
    if (oldRS.featureWeeklyReportEnabled !== newRS.featureWeeklyReportEnabled) oldRS.featureWeeklyReportEnabled = newRS.featureWeeklyReportEnabled;
    if (oldRS.featureYearlyReportEnabled !== newRS.featureYearlyReportEnabled) oldRS.featureYearlyReportEnabled = newRS.featureYearlyReportEnabled;
    if (oldRS.timezone !== newRS.timezone) oldRS.timezone = newRS.timezone;
    if (oldRS.weeklyReportDayOfWeek !== newRS.weeklyReportDayOfWeek) oldRS.weeklyReportDayOfWeek = newRS.weeklyReportDayOfWeek;
    if (JSON.stringify(oldRS.licensing) !== JSON.stringify(newRS.licensing)) oldRS.licensing = newRS.licensing;

    oldRS.version = newRS.optLock;

}

const updateRoom = (oldRoom, newRoom, state) => {
    //if(!newRoom.version || newRoom.version <= oldRoom.version )
    //  return;  
    const roomUp = {
        old: oldRoom,
        new: newRoom
    };
    let newPreview = false;

    oldRoom.dashboardPosition = newRoom.dashboardPosition;
    oldRoom.label = newRoom.label;
    oldRoom.externalID = newRoom.externalID;
    if (newRoom.authorizedUserOIDList) oldRoom.authorizedUserOIDList = JSON.parse(JSON.stringify(newRoom.authorizedUserOIDList));
    if (newRoom.lastPreviewImage) {
        newPreview = true;
        oldRoom.lastPreviewImage = JSON.parse(JSON.stringify(newRoom.lastPreviewImage))
        // if(state.watching && state.watchingOid === oldRoom.oid)
        {
            state.watchingImgToLoad = true
            state.watchingImgToLoadOid = oldRoom.lastPreviewImage && oldRoom.lastPreviewImage.imageOID ? oldRoom.lastPreviewImage.imageOID : ''
            state.watchingImgLoadedDate = oldRoom.lastPreviewImage && oldRoom.lastPreviewImage.date ? oldRoom.lastPreviewImage.date : ''
        }
    }
    if (newRoom.sensor) oldRoom.sensor = JSON.parse(JSON.stringify(newRoom.sensor));
    if (newRoom.userSensor) oldRoom.userSensor = JSON.parse(JSON.stringify(newRoom.userSensor));



    //console.log(`room update from ${oldRoom.version} to ${newRoom.version}`);
    //console.log(roomUp);
    oldRoom.version = newRoom.optLock;

}
const updateRoomSSE = (oldRoom, newRoom, state) => {
    if (newRoom.optLock === oldRoom.version) return;
    //if(!newRoom.version || newRoom.version <= oldRoom.version )
    //  return;    
    if (newRoom.lastPreviewImage && (!oldRoom.lastPreviewImage || (oldRoom.lastPreviewImage && oldRoom.lastPreviewImage.imageOID !== newRoom.lastPreviewImage.imageOID))) {
        oldRoom.lastPreviewImage = JSON.parse(JSON.stringify(newRoom.lastPreviewImage))
        if (state.watching && state.watchingOid === oldRoom.oid) {
            state.watchingImgToLoad = true
            state.watchingImgToLoadOid = oldRoom.lastPreviewImage && oldRoom.lastPreviewImage.imageOID ? oldRoom.lastPreviewImage.imageOID : ''
            state.watchingImgLoadedDate = oldRoom.lastPreviewImage && oldRoom.lastPreviewImage.date ? oldRoom.lastPreviewImage.date : ''
        }
    }



    if (oldRoom.dashboardPosition != newRoom.dashboardPosition) oldRoom.dashboardPosition = newRoom.dashboardPosition;
    if (oldRoom.label != newRoom.label) oldRoom.label = newRoom.label;

    if (!oldRoom.spokenTextOverride && newRoom.spokenTextOverride) oldRoom.spokenTextOverride = newRoom.spokenTextOverride;
    else if (oldRoom.spokenTextOverride && newRoom.spokenTextOverride) {
        oldRoom.spokenTextOverride.fr = newRoom.spokenTextOverride.fr;
        oldRoom.spokenTextOverride.nl = newRoom.spokenTextOverride.nl;
        oldRoom.spokenTextOverride.en = newRoom.spokenTextOverride.en;
    } else if (oldRoom.spokenTextOverride && !newRoom.spokenTextOverride)
        oldRoom.spokenTextOverride = newRoom.spokenTextOverride;


    if (oldRoom.externalID != newRoom.externalID) oldRoom.externalID = newRoom.externalID;
    if (JSON.stringify(oldRoom.authorizedUserOIDList) != JSON.stringify(newRoom.authorizedUserOIDList)) oldRoom.authorizedUserOIDList = JSON.parse(JSON.stringify(newRoom.authorizedUserOIDList));

    if (newRoom.sensor) oldRoom.sensor = JSON.parse(JSON.stringify(newRoom.sensor));
    if (newRoom.userSensor) oldRoom.userSensor = JSON.parse(JSON.stringify(newRoom.userSensor));

    if (
        ((!oldRoom.admissionList || !oldRoom.admissionList.length) && newRoom.admissionList && newRoom.admissionList.length // no known admission and a new one
            || oldRoom.admissionList[0].id != newRoom.admissionList[0].id // or adm id is not the same
            || oldRoom.admissionList[0].optLock != newRoom.admissionList[0].optLock // or adm version is not the same
        )) {
        oldRoom.admissionList = [newRoom.admissionList[newRoom.admissionList.length - 1]];
    }


    //console.log(`room update from ${oldRoom.version} to ${newRoom.version}`);
    //console.log(roomUp);


}

const updateAdmission = (oldAdm, newAdm, state) => {
    /*if(!newAdm.version || (newAdm.version <= oldAdm.version && newAdm.id == oldAdm.id) || oldAdm.id != newAdm.id)
      return;*/
    //if(!newAdm.id || !newAdm.version)
    //  return;
    //console.log("update admission " + oldAdm.id);
    let hasAlert = false;
    if (newAdm.inDate) oldAdm.inDate = newAdm.inDate;
    if (newAdm.outDate) oldAdm.outDate = newAdm.outDate;
    if (newAdm.patientExternalID) oldAdm.patientExternalID = newAdm.patientExternalID;
    if (newAdm.closeFollowUp) oldAdm.closeFollowUp = JSON.parse(JSON.stringify(newAdm.closeFollowUp));
    if (newAdm.id) oldAdm.id = newAdm.id;
    if (newAdm.stableState) oldAdm.stableState = JSON.parse(JSON.stringify(newAdm.stableState));
    if (newAdm.alert) oldAdm.alert = newAdm.alert; //this.updateAlert(oldAdm.alert,newAdm.alert);
    if (newAdm.alert) {
        if (
            oldAdm.alert.moderation.createdAt
            && oldAdm.alert.moderation.trueAlert
            && !oldAdm.alert.handled.createdAt
            && !oldAdm.alert.handling.createdAt
            && !oldAdm.alert.autoHandling.createdAt
            && oldAdm.alert.alertType === 5) {
            console.log(oldAdm)
            state.hasAlert = true;
            hasAlert = true;
        }
        if (oldAdm.alert.moderation.createdAt
            && oldAdm.alert.moderation.trueAlert
            && !oldAdm.alert.handled.createdAt
            && !oldAdm.alert.handling.createdAt
            && !oldAdm.alert.autoHandling.createdAt
            && oldAdm.alert.alertType === 4) {
            state.hasAlert = true;
            hasAlert = true;
        }
    }

    //console.log(`admission update from ${oldAdm.version} to ${newAdm.version}`);
    oldAdm.version = newAdm.version;
    return hasAlert;
}

const updateAlert = (oldAlert, newAlert) => {
    //    if(!newAlert.version || (newAlert.version <= oldAlert.version && newAlert.id == oldAlert.id))
    //        return;
    /*if(!newAlert.id || !newAlert.version)
    {
      console.log(`alert skip update  ${oldAlert.version} to ${newAlert.version}`);
      return;
    }*/

    if (newAlert) {
        oldAlert.autoHandling = newAlert.autoHandling;
        oldAlert.captureOID = newAlert.captureOID;
        oldAlert.code = newAlert.code;
        oldAlert.alertType = newAlert.alertType;
        oldAlert.createdAt = newAlert.createdAt;
        oldAlert.handled = newAlert.handled;
        oldAlert.handling = newAlert.handling;
        oldAlert.id = newAlert.id;
        oldAlert.moderation = newAlert.moderation;
        oldAlert.qualification = newAlert.qualification;
        oldAlert.triggerImage = newAlert.triggerImage;
        oldAlert.twilioList = newAlert.twilioList;
        console.log(`alert update from ${oldAlert.version}/${oldAlert.id} to ${newAlert.version}/${newAlert.id}`);
    }

}

const applyIncrementalShortPollResult = (state, newState, currentOrganizationLabel) => {
    let hasAlert = false;

    if (newState && newState.roomsetList)
        for (const aRoomset of newState.roomsetList) {
            const knownRoomset = state.roomsetList.find(rs => rs.oid == aRoomset.oid);
            if (!knownRoomset) {
                console.log("unkown roomset " + aRoomset.label);
                state.roomsetList.push(aRoomset)
            } else if (aRoomset.organizationLabel === currentOrganizationLabel) {
                updateRoomset(knownRoomset, aRoomset);
                for (const aRoom of aRoomset.roomList) {
                    const knownRoom = knownRoomset.roomList.find(r => r.oid == aRoom.oid);
                    if (!knownRoom) {
                        knownRoomset.roomList.push(aRoom);
                        console.log("unkown room " + aRoom.label);
                    } else {
                        updateRoom(knownRoom, aRoom, state);
                        for (const anAdmission of aRoom.admissionList) {
                            const knownAdmission = (knownRoom.admissionList && knownRoom.admissionList.length > 0) ? knownRoom.admissionList.find(a => a.id === anAdmission.id) : null;
                            if (!knownAdmission) {
                                console.log("unkown admission " + anAdmission.id + " at length " + knownRoom.admissionList.length);
                                if (!knownRoom.admissionList)
                                    knownRoom.admissionList = [];
                                //knownRoom.admissionList.push(anAdmission);
                            } else {
                                hasAlert = hasAlert || updateAdmission(knownAdmission, anAdmission, state);
                            }

                        }
                    }
                }
            }
        }

    state.hasAlert = hasAlert;

}

const clearRoomsetLicensingOptions = (value) => {
    const bedExitDisabled = !value.licensing || value.licensing.activeBedExitAllowed === 0;
    if (bedExitDisabled || !value.featureBedexitEnabled) {
        value.featureBedexitEnabled = false;

        for (let r of value.roomList) {
            if (r.sensor && r.sensor.settings && r.sensor.settings.enableBedExitDetection)
            {
                r.sensor.settings.enableBedExitDetection = false;
                r.sensor.settings.enableBedExitDetectionType = 0;
            }
        }
    }

    if (!value.licensing || !value.licensing.closeFollowUp)
        value.featureCloseFollowUpEnabled = false;
    if (!value.licensing || !value.licensing.weeklyMailReporting)
        value.featureWeeklyReportEnabled = false;
    if (!value.licensing || !value.licensing.monthlyMailReporting)
        value.featureMonthlyReportEnabled = false;
}

let _stateTimeout = null;

export default {
    SET_SSE_STATE(state, value) {    
        if(value && _stateTimeout)  clearTimeout(_stateTimeout);
        if(!value)
        {
            _stateTimeout = setTimeout(() => {
                state.sseState = value;
                state.sseStateChangeDate = new Date();
            }, 1000)
        }  else {
            state.sseState = value;
            state.sseStateChangeDate = new Date();
        }
    },
    SET_SP_UPDATE(state, { newState, isUpdate }) {
        if (isUpdate) {
            applyIncrementalShortPollResult(state, newState, state.currentOrganization)
            state.user = newState.user
        } else {
            state.roomsetList = newState.roomsetList
            state.user = newState.user
            let newOrgList = {};
            for (let org of state.roomsetList.map(p => p.organizationLabel))
                if (!newOrgList[org]) newOrgList[org] = true;
            if (state.organizationList && state.organizationList.length && Object.keys(newOrgList).length === 0) return;
            let orgList = Object.keys(newOrgList).sort((a, b) => a.localeCompare(b));
            for(let o of orgList)
                if(!state.organizationList.includes(o)) state.organizationList.push(o);

        }
        for (const rs of state.roomsetList) {
            rs.roomList = rs.roomList.sort((a, b) => (a.__NEW_dashboardPosition || a.dashboardPosition) === (b.__NEW_dashboardPosition || b.dashboardPosition) ? a.label.localeCompare(b.label) : (a.__NEW_dashboardPosition || a.dashboardPosition) - (b.__NEW_dashboardPosition || b.dashboardPosition))
            if (!(rs.oid in state.visibility))
                state.visibility[rs.oid] = true;
        }
        state.multipleRoomsetVisible = state.roomsetList.filter(p => p.organizationLabel === state.currentOrganization && state.visibility[p.oid] === true).length > 1;
        state.visibilityC++;
    },

    SET_SSE_ROOM(state, value) {
        let ra = false;
        if (state.roomsetList)
            for (const rs of state.roomsetList) {
                if (rs.organizationLabel === state.currentOrganization && rs.roomList) {
                    let lastPos = -100;
                    let lastLabel = 'a';
                    let orderSafe = true;
                    // update every rooms in every roomset (to keep in sync)
                    for (const r of rs.roomList) {
                        if (r.oid === value.oid) {
                            updateRoomSSE(r, value, state);
                        }

                        let pos = r.__NEW_dashboardPosition || r.dashboardPosition;
                        let os = true;
                        if (pos === lastPos)
                            os = r.label.localeCompare(lastLabel) >= 0;
                        else
                            os = lastPos < pos;
                        orderSafe &= os;
                        let rra = isRoomInAlert(r);
                        if (rra) ra = true;
                    }
                    if (!orderSafe)
                        rs.roomList = rs.roomList.sort((a, b) => (a.__NEW_dashboardPosition || a.dashboardPosition) === (b.__NEW_dashboardPosition || b.dashboardPosition) ? a.label.localeCompare(b.label) : (a.__NEW_dashboardPosition || a.dashboardPosition) - (b.__NEW_dashboardPosition || b.dashboardPosition))
                    if (!(rs.oid in state.visibility))
                        state.visibility[rs.oid] = true;
                }
            }
        if (ra) state.hasAlert = true;
        else state.hasAlert = false;
        state.multipleRoomsetVisible = state.roomsetList.filter(p => p.organizationLabel === state.currentOrganization && state.visibility[p.oid] === true).length > 1;
        state.visibilityC++;
    },
    SET_SSE_ROOMSET(state, value) {

        if (!state.roomsetList) return;
        for (const rs of state.roomsetList) {
            if (rs.oid === value.oid) {
                updateRoomset(rs, value, state)
            }
        }

        for (const rs of state.roomsetList) {
            clearRoomsetLicensingOptions(rs);
            // rs.roomList = rs.roomList.sort((a, b) => (a.__NEW_dashboardPosition || a.dashboardPosition) === (b.__NEW_dashboardPosition || b.dashboardPosition) ? a.label.localeCompare(b.label) : (a.__NEW_dashboardPosition || a.dashboardPosition) - (b.__NEW_dashboardPosition || b.dashboardPosition))          
            if (!(rs.oid in state.visibility))
                state.visibility[rs.oid] = true;
        }
        state.multipleRoomsetVisible = state.roomsetList.filter(p => p.organizationLabel === state.currentOrganization && state.visibility[p.oid] === true).length > 1;
        state.visibilityC++;
        // checkActiveAlerts(state);
    },
    SET_SSE_ALL(state, value) {
        if(!value) return;
        state.roomsetList = value.roomsetList;
        // state.users = value.users;    

        let newOrgList = {};
        for (let org of state.roomsetList.map(p => p.organizationLabel))
            if (!newOrgList[org]) newOrgList[org] = true;

        if (state.organizationList && state.organizationList.length && Object.keys(newOrgList).length === 0) {
            console.log('empty organization li st??')
        } else
        {
            let orgList = Object.keys(newOrgList).sort((a, b) => a.localeCompare(b));
            for(let o of orgList)
                if(!state.organizationList.includes(o)) state.organizationList.push(o);

            // state.organizationList = Object.keys(newOrgList).sort((a, b) => a.localeCompare(b));
        }

        if (!state.currentOrganization && state.organizationList.length)
            state.currentOrganization = state.organizationList[0]
        
        for (const rs of state.roomsetList) {
            clearRoomsetLicensingOptions(rs);
            rs.roomList = rs.roomList.sort((a, b) => (a.__NEW_dashboardPosition || a.dashboardPosition) === (b.__NEW_dashboardPosition || b.dashboardPosition) ? a.label.localeCompare(b.label) : (a.__NEW_dashboardPosition || a.dashboardPosition) - (b.__NEW_dashboardPosition || b.dashboardPosition))
            if (!(rs.oid in state.visibility))
                state.visibility[rs.oid] = true;
        }
        state.multipleRoomsetVisible = state.roomsetList.filter(p => p.organizationLabel === state.currentOrganization && state.visibility[p.oid] === true).length > 1;
        state.visibilityC++;
        checkActiveAlerts(state);
    },

    SET_SET_USERS(state, { users, audit }) {
        for (let u of users) {
            for (let rs of state.roomsetList) {
                if (rs.organizationLabel !== state.currentOrganization) continue
                if (u.role === 5) u['rightsOn' + rs.oid] = '-';
                else {
                    u['rightsOn' + rs.oid] = rs.authorizedUserOIDList.find(p => p === u.oid) != null ? 'yes' : 'no';
                }
                //"&#x2713;" : '&#x292B;'
            }
        }
        state.users = users;
        state.usersLastRefresh = new Date();
        state.phoneAudit = audit;
    },
    SET_SET_USER(state, user) {
        const kuser = state.users.find(p => p.oid === user);

        if (kuser) {
            for (let k in user)
                kuser[k] = user[k];
        }
    },

    SET_SET_INVITATIONS(state, invitations) {
        state.invitations = invitations;
        for (let i of state.invitations) {
            let d = moment(new Date(i.createdAt.toString()));
            i.createdAt = d.format('YYYY/MM/DD HH:mm');
        }

    },

    SET_RSAUTH(state, rs) {
        const krs = state.roomsetList.find(p => p.oid === rs.oid);
        if (krs) {
            krs.authorizedUserOIDList = rs.authorizedUserOIDList;
        }
    },


    SET_WATCHING_ROOM(state, { oid, label, enable }) {
        console.log('watch room ' + oid + ',' + label + ',' + enable)
        if (enable) {
            state.watching = true
            state.watchingLabel = label
            state.watchingImgLoaded = null
            state.watchingOid = oid
            state.watchingImgLoadedDate = null;
        }
        else {
            state.watching = false;
            state.watchingLabel = null
            state.watchingImgLoaded = null
            state.watchingImgLoadedDate = null;
            state.watchingOid = null
        }
    },

    CHANGE_ROOMLABEL(state, room) {
        if (room && room.oid) {
            for (const aRoomset of state.roomsetList) {
                for (const aRoom of aRoomset.roomList)
                    if (aRoom.oid === room.oid) {
                        aRoom.label = room.label;
                        return;
                    }
            }
        }
    },

    SET_ROOM_POSITION(state, room) {
        if (room && room.oid) {
            for (const aRoomset of state.roomsetList) {
                for (const aRoom of aRoomset.roomList)
                    if (aRoom.oid === room.oid) {
                        // aRoom.dashboardPosition = room.dashboardPosition;
                        aRoom.__NEW_dashboardPosition = room.dashboardPosition;
                        return;
                    }
            }
        }
    },

    SET_WATCHING_IMAGE(state, img) {
        state.watchingImgToLoad = false
        state.watchingImgToLoadOid = ''
        state.watchingImgLoaded = img
        state.watchingImgLoadedDate = new Date()
    },

    SET_WATCHING_VIDEO(state, alert) {
        if (alert) {
            console.log('enabling video watcher')
            state.watchingVideo = true;
        } else
            state.watchingVideo = false;

        console.log(alert)
        state.watchingVideoOid = alert ? alert.videoUrl : null;
        state.watchingVideoAlert = alert;
    },

    SET_WATCHING_NOTIFICATIONHISTORY(state, alert) {
        if (alert) {
            state.watchingNotificationHistory = true;
        } else
            state.watchingNotificationHistory = false;
        state.watchingNotificationHistoryData = alert;
    },

    SET_WATCHING_SENSORCONNECTIONHISTORY(state, sensor) {        
        if (sensor) {
            state.watchingSensorConnectivityHistory = true;
        } else
            state.watchingSensorConnectivityHistory = false;
        state.watchingSensorConnectivityHistoryData = sensor;
    },

    CRON_SECOND(state) {
        state.cronSecond = !state.cronSecond;
    },
    CRON_MINUTE(state) {
        state.cronMinute = !state.cronMinute;
    },

    SET_ORGANIZATION_INFO(state, value) {
        state.organizationInfo = value;
    },

    SET_CURRENT_ORGANIZATION(state, value) {
        state.hasAlert = false;
        state.currentOrganization = value
        checkActiveAlerts(state)
    },

    SET_ROOMSET_VISIBLE(state, value) {
        state.visibility[value.roomset.oid] = value.visible;
        state.multipleRoomsetVisible = state.roomsetList.filter(p => p.organizationLabel === state.currentOrganization && state.visibility[p.oid] === true).length > 1;
        console.log(state.visibility)
        state.visibilityC++;
    },
    ROOM_WIDTH(state, value) {
        state.roomWidth = value;
    },

    SET_CQS(state, value) {            
        state.cqs = value;
    },

    /******************************************************************************** */
    /*  REPORT STUFF ***********/
    APPEND_INFRAREPORT(state, { infraList, currentOrganization, roomsetList }) {
        // state.infraList = infraList.contents;


        const externalIdMapping = {};
        for (const rs of roomsetList.filter(p => p.organizationLabel === currentOrganization && p.label.charAt(0) !== '_'))
            for (const r of rs.roomList)
                externalIdMapping[r.externalID] = {
                    r,
                    rs,
                }
        const result = [];
        for (const io in infraList) {
            if (externalIdMapping && externalIdMapping[io] && externalIdMapping[io].rs.label.charAt(0) !== '_') {
                const o = {};
                o.room_oid = externalIdMapping[io].r.oid;
                o.room_label = externalIdMapping[io].r.label;
                o.rs_label = externalIdMapping[io].rs.label;
                o.events = infraList[io];
                result.push(o);
            }
        }
        state.infraList = result;
    },

    APPEND_REPORT(state, { welcome, alerts, currentOrganization, roomsetList }) {
        let start = new Date();
        if (welcome) {
            state.welcome.userCount = welcome.userCount;
            state.welcome.phoneCount = welcome.phoneCount;
        }
        
        console.log('APPEND_REPORT welcome done -> ' + (new Date() - start) + 'ms' );
        let toAdd;
        if (Array.isArray(alerts)) {
            toAdd = alerts;
        } else toAdd = [alerts];

        console.log('APPEND_REPORT prep done -> ' + (new Date() - start) + 'ms' );
        if (!state.alertList)
            state.alertList = []                    

            
        const sta = JSON.parse(JSON.stringify(state.alertList));
        console.log('APPEND_REPORT sta clone done -> ' + (new Date() - start) + 'ms' );
        const etoAdd = [];
        for (let a of toAdd) {
            if (a && sta.find(p => p.video === a.video) == null)
                etoAdd.push(a);
        }        
        console.log('APPEND_REPORT sta prep done -> ' + (new Date() - start) + 'ms' );
        sta.push(...etoAdd);
        
        console.log('APPEND_REPORT sta populated done -> ' + (new Date() - start) + 'ms' );
        state.alertList = Object.freeze(sta);
        console.log('APPEND_REPORT state done -> ' + (new Date() - start) + 'ms' );

        if (toAdd.length || !state.alertListPerMonthSummaryOrganization || state.alertListPerMonthSummaryOrganization != currentOrganization) {
            const perMonth = {};
            for (const a of state.alertList.filter(a => a.organizationLabel === currentOrganization)) {
                const m = moment(a.date).format('MM/YY');
                if (!perMonth[m]) perMonth[m] = { be: 0, f: 0 };
                if (a.type === 5) perMonth[m].f++;
                if (a.type === 4) perMonth[m].be++;
            }

            const months = [moment().add(-6, 'month').format('MM/YY'), moment().add(-5, 'month').format('MM/YY'), moment().add(-4, 'month').format('MM/YY'), moment().add(-3, 'month').format('MM/YY'), moment().add(-2, 'month').format('MM/YY'), moment().add(-1, 'month').format('MM/YY'), moment().format('MM/YY')];
            const series = [
                { name: i18n.t('Fall Events'), data: [] },
                { name: i18n.t('Bed Exit Events'), data: [] },
            ];
            for (const m of months) {
                let f = 0;
                let be = 0;
                if (perMonth[m]) {
                    f = perMonth[m].f;
                    be = perMonth[m].be;
                }
                series[0].data.push(f);
                series[1].data.push(be);
            }

            state.alertListPerMonthSummary = series;
            state.alertListPerMonthSummaryOrganization = currentOrganization;

            try {
                let pm = perMonth[moment().add(-1, 'month').format('MM/YY')];
                if (!pm) pm = { f: 0, be: 0 };
                state.alertListLastMonthSummary = {
                    fallAlerts: pm.f,
                    bedExits: pm.be,
                }
            } catch (err) {
                console.error(err);
            }
        }
        state.roomsetList = roomsetList.filter(rs => rs.organizationLabel === currentOrganization);
        console.log('APPEND_REPORT done -> ' + (new Date() - start) + 'ms' );
    },

    RELOAD_GRAPH(state, {}) {
        const perMonth = {};
        for (const a of state.alertList.filter(a => a.organizationLabel === state.currentOrganization)) {
            const m = moment(a.date).format('MM/YY');
            if (!perMonth[m]) perMonth[m] = { be: 0, f: 0 };
            if (a.type === 5) perMonth[m].f++;
            if (a.type === 4) perMonth[m].be++;
        }

        const months = [moment().add(-6, 'month').format('MM/YY'), moment().add(-5, 'month').format('MM/YY'), moment().add(-4, 'month').format('MM/YY'), moment().add(-3, 'month').format('MM/YY'), moment().add(-2, 'month').format('MM/YY'), moment().add(-1, 'month').format('MM/YY'), moment().format('MM/YY')];
        const series = [
            { name: i18n.t('Fall Events'), data: [] },
            { name: i18n.t('Bed Exit Events'), data: [] },
        ];
        for (const m of months) {
            let f = 0;
            let be = 0;
            if (perMonth[m]) {
                f = perMonth[m].f;
                be = perMonth[m].be;
            }
            series[0].data.push(f);
            series[1].data.push(be);
        }

        state.alertListPerMonthSummary = series;
        state.alertListPerMonthSummaryOrganization = state.currentOrganization;

        try {
            let pm = perMonth[moment().add(-1, 'month').format('MM/YY')];
            if (!pm) pm = { f: 0, be: 0 };
            state.alertListLastMonthSummary = {
                fallAlerts: pm.f,
                bedExits: pm.be,
            }
        } catch (err) {
            console.error(err);
        }
    },


    SET_DOWNLOADABLE(state, data) {
        state.downloadableList = data;
    },



    SET_REPORT_FILTER(state, value) {
        state.filter = value;
    },

    SET_REPORT_STATS(state, value) {        
        state.filteredAlertList = Object.freeze(value.filteredAlertList);
        state.filteredStats = value.filteredStats;
        state.roomSummary = value.roomSummary;
    },

    SET_ERROR(state, value) {
        state.error = value;
    },

    SET_INSIGHT_MODE(state, value) {
        state.mode = value;
    },

    SET_UPDATING_STATS(state, value) {
        state.updatingStat = value;        
    },

    SET_PADMISSIONS(state, value) {
        state.pAdmissions = value;
    },

    RESET_DATA_CACHE(state) {        
        state.roomsetList= [];        
        state.organizationList= [];
        state.visibility= {};
        state.visibilityC= 0;
        state.users= [];
        state.invitations= [];
        state.currentOrganization= null;
        state.currentLicensing= null;
        state.organizationInfo= null;
        state.watching= false;
        state.watchingOid= null;
        state.watchingLabel= null;
        state.watchingImgLoaded= null;
        state.watchingImgToLoad= false;
        state.watchingImgToLoadOid= '';
        state.watchingVideo= false;
        state.watchingVideoOid= null;
        state.watchingVideoAlert= null;
        state.watchingNotificationHistory= false;
        state.watchingNotificationHistoryData= {};
        state.watchingSensorConnectivityHistory= false;
        state.watchingSensorConnectivityHistoryData= {};
        state.cronSecond= false;
        state.cronMinute= false;
        state.multipleRoomsetVisible= true;
        state.roomWidth= 4;
        state.sseState= false;
        state.sseStateChangeDate= null;
        state.cqs= [];        
        state.welcome= {
            userCount: -1,
            phoneCount: -1,
        };
        state.fallList= [];
        state.alertList= [];
        state.infraList= [];
        state.filter= {
            timeRangeChoice: 0,
            dateFilter: {
                start: moment().add(-3, "months").toISOString(),
                end: moment().toISOString(),
            },
            showFall: true,
            showBedExit: true,
            roomFilterValues: [],
            showND: true,
            showFA: false,
            showTD: true,
            showUN: true,
            showTEST: false,
        };

        state.filteredAlertList= [];
        state.alertListPerMonthSummary= null;
        state.alertListLastMonthSummary= null;
        state.alertListPerMonthSummaryOrganization= null;
        state.filteredStats= {
            fallEstimatedCount: '?',
            bedExitAlertCount: '?',
            fallAlertCount: '?',
            falseAlertsCount: '?',
            callsCount: '?',
            callsResponseTime: {
                average: '?',
            },
            alertingResponseTime: {
                average: '?',
            },
            callsUnansweredCount: '?',
        };
        state.roomSummary= [];
        state.error= '';
        state.updatingStat= false;
        state.reloadSSE= 0; // to force reloading sse
    }
}
