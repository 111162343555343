import $store from '@/store'
import { AuthService } from '@/services/auth.service.js'
import { SPService } from '@/services/sp.service.js'
import * as Fingerprint2 from 'fingerprintjs2'
import * as UAParser from 'ua-parser-js'

function _getFingerprint () {
  return new Promise((resolve, reject) => {
    async function getHash () {
      const options = {
        excludes: {
          plugins: true,
          localStorage: true,
          adBlock: true,
          screenResolution: true,
          availableScreenResolution: true,
          enumerateDevices: true,
          pixelRatio: true,
          doNotTrack: true,
          preprocessor: (key, value) => {
            if (key === 'userAgent') {
              const parser = new UAParser(value)
              // return customized user agent (without browser version)
              return `${parser.getOS().name} :: ${parser.getBrowser().name} :: ${parser.getEngine().name}`
            }
            return value
          }
        }
      }

      try {
        const components = await Fingerprint2.getPromise(options)
        const values = components.map(component => component.value)
        
        return String(Fingerprint2.x64hash128(values.join(''), 31))
      } catch (e) {
        reject(e)
      }
    }

    if (window.requestIdleCallback) {
      console.log('get fp hash @ requestIdleCallback')
      requestIdleCallback(async () => resolve(await getHash()))
    } else {
      console.log('get fp hash @ setTimeout')
      setTimeout(async () => resolve(await getHash()), 500)
    }
  })
}
let fingerprint = null; 


export default (to, from, next) => {
  console.log('NAVIGATE TO ' + (to ? to.fullPath : '???'));
  

  if(to && to.fullPath === '/app/sessions/signIn') 
    return next();
  if(to && to.fullPath === '/app/sessions/forgot') 
    return next();
  if(to && to.fullPath.startsWith('/app/sessions/reset')) 
    return next();
  if(to && to.fullPath.startsWith('/app/sessions/invitation')) 
    return next();
   if(to && to.fullPath.startsWith('/switchapi')) 
    return next();
    if(to && to.fullPath.startsWith('/fulluserguide')) 
    return next();
    if(to && to.fullPath.startsWith('/rekiosk')) 
    return next();

    
    if(!$store.state.fleet.currentOrganization && ($store.state.user.currentUser.oid || $store.state.user.kioskUser.oid))
    {
      
        $store.dispatch('fleet/setCurrentOrganization', $store.state.user.currentUser.organizationLabel || $store.state.user.kioskUser.organizationLabel, { root: true });
    }

  if(to && to.fullPath && !AuthService.checkLogin())
  {
    if($store.state.user.kioskUser.oid && to.fullPath === '/app/kiosk')   
        return next(); 
    if($store.state.user.kioskUser.oid && to.fullPath !== '/app/kiosk')   
    {
      if(from && from.fullPath === '/app/kiosk')
        return next();
      return next("/app/kiosk"); 
    }
    // not logged and going elsewhere, save the original destination first    
    $store.commit('user/SET_URLBEFORESIGNIN', to.fullPath)    
    return next("/app/sessions/signIn");
  }


  if (!$store.state.user.currentUser.oid && !$store.state.user.kioskUser.oid) {    
    localStorage.removeItem("userInfo");
    next("/app/sessions/signIn");
  } else {
    
    if(fingerprint === null) {
      _getFingerprint().then(p => {
        $store.state.user.fingerprint = p;
        fingerprint = p;
        const data = {
          fingerprint: fingerprint,
          action: 'navigate',
          path: (to ? to.fullPath : '???'),
          data: window.location.href,
        }

        SPService.wbevent($store.state.user.currentUser.apiUrl, $store.state.user.currentUser.jwt, data);
      })
    } else {
      const data = {
        fingerprint: fingerprint,
        action: 'navigate',
        path: (to ? to.fullPath : '???'),
        data: window.location.href,
      }

      SPService.wbevent($store.state.user.currentUser.apiUrl, $store.state.user.currentUser.jwt, data);
    }
      

    next();
  }
};
