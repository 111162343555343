import Vue from "vue";
import store from "./store";
import Router from "vue-router";
import NProgress from "nprogress";
import authenticate from "./auth/authenticate";


Vue.use(Router);

// create new router

const routes = [  
  {
    path: "/",
    component: () => import("./views/app"),
   
    children: [
      {
        name: 'welcome',
        path: "/app/welcome",
        component: () => import("./views/app/isa/welcome"),
        children: []
      },
      {
        path: "/app/reporting",
        component: () => import("./views/app/isa/reporting"), 
        children: []
      },
      {
        path: "/app/usersmanagement",
        component: () => import("./views/app/isa/usersmanagement"), 
        children: []
      },
      {
        path: "/app/notificationsmanagement",
        component: () => import("./views/app/isa/notificationsmanagement"), 
        children: []
      },
      {
        path: "/app/roommanagement",
        component: () => import("./views/app/isa/roommanagement"), 
        children: []
      },
      {
        path: "/app/kiosk",
        component: () => import("./views/app/isa/kiosk"), 
        children: []
      },
      {
        path: "/app/userguide",
        component: () => import("./views/app/isa/userguide"), 
        props: route => ({ hi: route.params.hi || route.query.hi }),
        children: []
      },      
      {
        path: "/app/userguide/:page",
        component: () => import("./views/app/isa/userguide"), 
        props: route => ({ userguidepage: route.params.page }),
        children: []
      },
      {
        path: "/capture/:captureOid",
        component: () => import("./views/app/isa/reporting"), 
        props: route => ({ videolink: route.params.captureOid }),
        children: []
      },
      {
        path: "/purge/:type",
        component: () => import("./components/ISA/session/ISAAbout"), 
        props: route => ({ purgeType: route.params.type }),
        children: []
      },
      {
        path: "/switchapi",
        component: () => import("./components/ISA/session/ISAAbout"), 
        props: route => ({ purgeType: 'switchurl', url: route.query.url }),
        children: []
      },

    ]
  },
  // sessions
  {
    path: "/app/sessions",
    component: () => import("./views/app/sessions"),
    redirect: "/app/sessions/signIn",
    children: [
      {
        path: "signIn",
        component: () => import("./views/app/sessions/signIn")
      },
      {
        path: "signUp",
        component: () => import("./views/app/sessions/signUp")
      },
      {
        path: "forgot",
        component: () => import("./views/app/sessions/forgot")
      },      
      {
        path: "reset",
        component: () => import("./views/app/sessions/reset")
      },      
      {
        path: "invitation",
        component: () => import("./views/app/sessions/invitation")
      },
      
    ]
  },
  {
    path: "/fulluserguide",
    component: () => import("./views/app/isa/fullguide"), 
    props: route => ({ lang: route.query.lang, guide: route.query.guide, jwt: route.query.jwt }),
    children: []
  },
  {
    path: "/rekiosk",
    component: () => import("./views/app/isa/rekiosk"), 
    props: route => ({ lang: route.query.lang, org: route.query.org, jwt: route.query.jwt }),
    children: []
  },
  {
    path: "*",
    component: () => import("./views/app/isa/welcome")
  },
  {
    path: "",
    component: () => import("./views/app/isa/welcome")
  }
];

const router = new Router({
  mode: "history",
  linkActiveClass: "open",
  routes,  
  scrollBehavior(to, from, savedPosition) {
    return {x: 0, y: 0};
  }
});

router.beforeEach((to, from, next) => {
  if(to.query.kioskable) {
      if(to.query.kioskable === 'off' || to.query.kioskable === 'false')          
          store.commit('user/SET_KIOSKABLE', false);
      else
          store.commit('user/SET_KIOSKABLE', true);
          
  }
      
  // If this isn't an initial page load.
  if (to.path) {
    // Start the route progress bar.    
    NProgress.start();
    NProgress.set(0.1);    
    authenticate(to, from, next);
  } else
    next();
});

router.afterEach((to, from) => {
  // Remove initial loading
  const gullPreLoading = document.getElementById("loading_wrap");
  if (gullPreLoading) {
    gullPreLoading.style.display = "none";
  }
  // Complete the animation of the route progress bar.
  
  setTimeout(() => NProgress.done(), 500);
  // NProgress.done();
  // if (isMobile) {
  if(to.path === '/app/kiosk' || store.state.user.isMobile)
      store.dispatch("closeSidebar");

  try {    
    // electron stuff    
    if(window.api && window.api.setPath)
      window.api.setPath(to.path);
  }
  catch(e) {
    console.log('No electron api configuration found');
  }

});

export default router;
