const ROLES = {
    kiosk: 0,
    securitas: 1,
    nurse: 2,
    doctor: 3,
    admin: 4,
    adminMintt: 5,
    worker: 6,
    sensors: 7,
    electrician: 8,
    rasbpi: 9,
    moderator: 10,    
    medAdmin: 11
  };
  
  export default ROLES;
  