import { UsersService } from '@/services/users.service'
import { SPService } from '@/services/sp.service'
import { AuthService } from '@/services/auth.service'
import AES from 'crypto-js/aes';
import { API_URL } from '@/.env';
import generator from 'generate-password';
import router from "@/router";

export default {
  getCurrent ({ commit }) {
    return UsersService.getCurrent()
      .then(user => commit('SET_CURRENT_USER', user.data))
      .catch(error => commit('toast/NEW', { type: 'error', message: error.message }, { root: true }))
  },

  async loginKioskFromElectron({state, commit, dispatch}, creds) {
    let newUser = await AuthService.makeLogin({
        apiUrl: API_URL.url,
        username: creds.username,
        password: creds.password,
	      organizationLabel: creds.organizationLabel || 'mintt'
    });

    if (newUser && newUser.status == 200) {          
      newUser.data.organizationLabel = creds.organizationLabel || 'mintt'
      newUser.data.apiUrl = API_URL.url;
      commit('SET_KIOSK_USER', newUser.data);     
      dispatch('fleet/setCurrentOrganization',creds.organizationLabel || 'mintt', { root: true });     
    }
  },


  setCurrent ({state, commit, dispatch}, { user, password }) {
    // first, save the user as current user
    console.log('set current user:' )
    console.log(user);
    if(state.currentUser && state.currentUser.role !== 0 && user.role === 0) {
      commit('SET_KIOSK_USER', user)      
    } else {
      commit('SET_CURRENT_USER', user)
      if(user.role === 0)
        commit('SET_KIOSK_USER', user)      
    }     
    
    dispatch('fleet/setCurrentOrganization',user.organizationLabel, { root: true });
    
    // eventually save default kiosk user        
    if(user.role !== 0) { // kiosk user      
      if(!state.knownUsers.find(u => u.oid === user.oid) && (state.isMobile || state.deviceIsKiosk)) // this is not a kiosk user, then we'll propose to store a pattern      
            commit('SET_NEEDS_LOCKPATTERN', { enable: true, password: password})
      if(user.needNewPassword)
            commit('SET_NEEDS_CHANGEPASSWORD',true)
    }
  },
  promptPasswordChange({ state, commit}, { oldPassword, newPassword}) {
    commit('SET_NEEDS_CHANGEPASSWORD', true)
  },

  promptPattern({commit}, { user, password }) {
    commit('SET_NEEDS_LOCKPATTERN', { enable: true, password: password})
  },

  logout({commit, state }) {    
    if(state.kioskUser.jwt) // a kiosk user exists, we set it as current user and notify that session has expired    
      commit('SET_CURRENT_USER', state.kioskUser);   
    else        
      commit('SET_CURRENT_USER', {})
  },

  logoutKiosk({commit}) {
    console.log('action logoutKiosk')
    commit('SET_KIOSK_USER', {})   
  },
  
  needsLogin ({commit, state,  watch}, needs) {    
    commit('SET_NEEDS_LOGINDATA', needs)
    if(needs)
      commit('SET_NEEDS_LOGIN', true)
    else 
    {
      commit('SET_NEEDS_LOGINDATA', null)    
      commit('SET_NEEDS_LOGIN', false)
    }
  },

  needsForgot({commit, state,  watch}, needs) {    
    if(needs)
      commit('SET_NEEDS_FORGOT', true)
    else 
      commit('SET_NEEDS_FORGOT', false)
  },

  needsKioskLogin ({commit, state,  watch}, needs) {     
    if(needs)
      commit('SET_NEEDS_KIOSKLOGIN', true)
    else     
      commit('SET_NEEDS_KIOSKLOGIN', false)
  },

  editBedSettings({ commit, state}, room) {
    commit('user/SHOW_BEDEXITSETTINGS', room)
  },

  async needsKioskCreatesUser ({commit, rootState, state,  watch}, args) {    
      // creates a kiosk user and log with it
      
      let randomName = 'kiosk_' + generator.generate({
        length: 6,
        numbers: false
      });
      let password = generator.generate({
        length: 32,
        numbers: true
      });

      const jwt = args && args.jwt ? args.jwt : state.currentUser.jwt;
      const org = args && args.org ? args.org : rootState.fleet.currentOrganization;

      console.log('generate random user/password for kiosk ' + randomName + '/' + password + ' in organization ' + org);
      try
      {
        // 1st, creates a new kiosk user        
        let answer = await SPService.createUser(API_URL.url, jwt, 
          {              
              "roomsetOID": rootState.fleet.roomsetList.filter(p => p.organizationLabel === org)[0].oid,
              "username": randomName,
              "password": password,
              "email": randomName + '@' + org + '.care' ,
              "phone": '',
              "isCall": false,
              "firstName": '',
              "lastName": '',
              "role": 0, // kiosk
              "needNewPassword": false,
              "organizationLabel": org,    
              apiUrl: API_URL.url,                    
          }          
        );
        // now log into this new kiosk user
        let newUser = await AuthService.makeLogin({
          apiUrl: API_URL.url,
          username: randomName,
          password: password,
          organizationLabel: org
        });
        this.error = "";
        console.log(newUser);
        if (newUser && newUser.status == 200) {          
          newUser.data.organizationLabel = org
          newUser.data.apiUrl = API_URL.url;
          commit('SET_KIOSK_USER', newUser.data);          
        }
        // give rights of this new kiosk to all roomsets by default
        // needs to wait that fleet roomsetlist is filled...bourrin
                
        for(let rs of rootState.fleet.roomsetList.filter(r => r.organizationLabel === org  && r.label.charAt(0) !== '_')) {          
            // needs to add user rights   
            debugger           
            await SPService.addUserToRoomset(API_URL.url, jwt, newUser.data.oid, rs);    
            /*          
            this.$bvToast.toast( this.$t('Access to {roomset} given to {username}',{ roomset: rs.label, username: newUser.username}), {
                title: this.$t('User Right Mangagement'),            
                solid: true,
                variant: 'success',
                duration: 5000
              });*/
        }

      } catch(err) {
        console.error(err);
      }
      


  },

  savePattern ({commit}, { user, secret , password, apiUrl }) {    
    // encrypt the user password with the given secret (lock pattern)
    const encryptedPassword = AES.encrypt(password, secret).toString()
    commit('SET_USER_PASSWORD', { user, encryptedPassword, apiUrl})
    commit('SET_NEEDS_LOCKPATTERN', { enable: false, password: null})
    commit('SET_NEEDS_CHANGEPATTERN', false)
  },

  changePassword ({commit}, { user, oldPassword , newPassword, apiUrl }) {    
    commit('SET_NEEDS_CHANGEPASSWORD', false)
  },

  showAbout({commit}, show) {
    commit('SET_SHOW_ABOUT', show)
  },
  async resetUserCache({ state, commit, dispatch}) {
    await commit('RESET_USER_CACHE');
                
  }

}
