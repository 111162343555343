const state = {
  sidebarToggleProperties: {
    isSideNavOpen: true,
    isSecondarySideNavOpen: false,
    isActiveSecondarySideNav: false
  }
};

const getters = {
  getSideBarToggleProperties: state => state.sidebarToggleProperties
};

const actions = {
  changeSidebarProperties({rootState, commit}) {
    
    if(rootState.user.currentUser && rootState.user.currentUser.role === 0)
      return  commit("closeSidebarProperties");
    
    commit("toggleSidebarProperties");
  },
  closeSidebar({rootState, commit}) {        
      return  commit("closeSidebarProperties");    
  },


  changeSecondarySidebarProperties({commit}) {
    commit("toggleSecondarySidebarProperties");
  },
  changeSecondarySidebarPropertiesViaMenuItem({commit}, data) {
    commit("toggleSecondarySidebarPropertiesViaMenuItem", data);
  },
  changeSecondarySidebarPropertiesViaOverlay({commit}) {
    commit("toggleSecondarySidebarPropertiesViaOverlay");
  }
};

const mutations = {
  closeSidebarProperties: state => 
  (state.sidebarToggleProperties.isSideNavOpen = false),  

  toggleSidebarProperties: state => 
    (state.sidebarToggleProperties.isSideNavOpen = !state
      .sidebarToggleProperties.isSideNavOpen),  
    

  toggleSecondarySidebarProperties: state =>
    (state.sidebarToggleProperties.isSecondarySideNavOpen = !state
      .sidebarToggleProperties.isSecondarySideNavOpen),
  toggleSecondarySidebarPropertiesViaMenuItem(state, data) {
    state.sidebarToggleProperties.isSecondarySideNavOpen = data;
    state.sidebarToggleProperties.isActiveSecondarySideNav = data;
  },
  toggleSecondarySidebarPropertiesViaOverlay(state) {
    state.sidebarToggleProperties.isSecondarySideNavOpen = !state
      .sidebarToggleProperties.isSecondarySideNavOpen;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
