import Vue from 'vue';
import VueApexCharts from 'vue-apexcharts'
import nl from "apexcharts/dist/locales/nl.json";
import en from "apexcharts/dist/locales/en.json";
import fr from "apexcharts/dist/locales/fr.json";

Vue.mixin({
    data: function() {
      return {
        get apexLocales() {
          return [fr, nl, en];
        }
      }
    }
  })


Vue.use(VueApexCharts)
 
Vue.component('apexchart', VueApexCharts)