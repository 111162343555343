import * as Fingerprint2 from 'fingerprintjs2'
import * as UAParser from 'ua-parser-js'
import axios from 'axios'

import { Http } from './http.init'
import { ResponseWrapper, ErrorWrapper } from './util'
import $store from '../store'
import $router from '../router'
import { NativeEventSource, EventSourcePolyfill } from 'event-source-polyfill';

// import { API_URL } from '../.env'

let BEARER = ''
function _getFingerprint () {
  return new Promise((resolve, reject) => {
    async function getHash () {
      const options = {
        excludes: {
          plugins: true,
          localStorage: true,
          adBlock: true,
          screenResolution: true,
          availableScreenResolution: true,
          enumerateDevices: true,
          pixelRatio: true,
          doNotTrack: true,
          preprocessor: (key, value) => {
            if (key === 'userAgent') {
              const parser = new UAParser(value)
              // return customized user agent (without browser version)
              return `${parser.getOS().name} :: ${parser.getBrowser().name} :: ${parser.getEngine().name}`
            }
            return value
          }
        }
      }

      try {
        const components = await Fingerprint2.getPromise(options)
        const values = components.map(component => component.value)        
        return String(Fingerprint2.x64hash128(values.join(''), 31))
      } catch (e) {
        reject(e)
      }
    }

    if (window.requestIdleCallback) {
      console.log('get fp hash @ requestIdleCallback')
      requestIdleCallback(async () => resolve(await getHash()))
    } else {
      console.log('get fp hash @ setTimeout')
      setTimeout(async () => resolve(await getHash()), 500)
    }
  })
}
let fingerprint = null; 


export class SPService {


  static _boucingTimes = {};
  static bounce(call, limit = 1000) {
    
    if(!this._boucingTimes) 
    {
      SPService._boucingTimes[call] = Date.now();
      return false;
    }
    if(Date.now()- SPService._boucingTimes[call] < limit ) return true;
    SPService._boucingTimes[call] = Date.now();
    return false;    
  }

  /**
   ******************************
   * @API
   ******************************
   */

  static async shortpoll (apiUrl, jwt, spState) {
    try {
      const response = await axios.post(`${apiUrl}/short-polling`,
      spState, {
        headers: {
          Authorization: 'Bearer ' + jwt
        }
      });      
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      console.log(error);
      return new ResponseWrapper(error.response, error)
    }
  }

  static async getSSEURL (apiUrl, jwt, organizationLabel) {
    let response = null;
    try {      
      if(!fingerprint) fingerprint = await _getFingerprint();
      response = await axios.post(`${apiUrl}/short-polling/spsseurl`,
      { fingerprint, organizationLabel }, {
        headers: {
          Authorization: 'Bearer ' + jwt
        }
      });      
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      
      console.error(error);          
      return error.response ? error.response.data : error;
    }
  }
  static lastSSEConnectionAttempt = null;

  static async shortpollSSE (apiUrl, jwt, listener) {  

    if(SPService.lastSSEConnectionAttempt && new Date() - SPService.lastSSEConnectionAttempt < 1000 )
    await new Promise((resolve) => {
        setTimeout(resolve, 2000);
      });
      SPService.lastSSEConnectionAttempt = new Date();

    const url = await SPService.getSSEURL(apiUrl, jwt);
    const MEventSource = NativeEventSource || EventSourcePolyfill;
    var es = new MEventSource(url, {
      headers: {
        Authorization: 'Bearer ' + jwt
      }
    });

    es.addEventListener("open", listener);
    es.addEventListener("message", listener);
    es.addEventListener("error", listener);    
  }

  static async checkAuth (apiUrl, jwt) {    
    try
    {
      const response = await axios.get(`${apiUrl}/short-polling/checkauth?timestamp=${new Date().getTime()}`,
      {
        headers: {
          Authorization: 'Bearer ' + jwt
        }
      });    
      if(response.status === 200)  
        return 0;    
        console.log('check Auth failed with answer')
      console.log(response); 
      return 5; 
    } catch (err) {     
      console.log('check Auth failed with error')
      if(err.message === 'Network Error')
        return 1;
      console.log(err); 
      return 5; // this is a bad auth, high return to disconnect user
    }
  }

  

  static async whoami (apiUrl, jwt) {
    try {
      const response = await axios.get(`${apiUrl}/user/whoami?timestamp=${new Date().getTime()}`,
      {
        headers: {
          Authorization: 'Bearer ' + jwt
        }
      });      
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      console.log(error);
      return new ResponseWrapper(error.response, error)
    }
  }

  static async alive (apiUrl) {
    try {
      const response = await axios.get(`${apiUrl}/alive?timestamp=${new Date().getTime()}`);
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      console.log(error);
      return new ResponseWrapper(error.response, error)
    }
  }

  static async wbevent (apiUrl, jwt, data) {
    if(!apiUrl) return;
    try {
      if(!fingerprint) fingerprint = await _getFingerprint();
      if(!data.fingerprint) data.fingerprint = fingerprint;

      const response = await axios.post(`${apiUrl}/kiosk/ev`,
      data,
      {
        headers: {
          Authorization: 'Bearer ' + jwt
        }
      });      
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      console.log(error);
      return new ResponseWrapper(error.response, error)
    }
  }

  static async report(apiUrl, jwt, organization, since, offset) {
    try {            
      const response = await axios.get(`${apiUrl}/report?timestamp=${new Date().getTime()}&org=${organization}&${offset ? 'offset=' + offset + (since ? '&' : ''): ''}${(since) ? 'since=' + since : ''}`,
      {
        headers: {
          Authorization: 'Bearer ' + jwt
        }
      });
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      console.log(error);
      return new ResponseWrapper(error.response, error)
    }
  }

  static async loadDoc_reports(apiUrl, jwt, org) {
    try {            
      const response = await axios.get(`${apiUrl}/report/download${org ? ('?org='+org) : ''}`,
      {
        headers: {
          Authorization: 'Bearer ' + jwt
        }
      });
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      console.log(error);
      return new ResponseWrapper(error.response, error)
    }
  }

  static async loadOrganizationInfo(org, apiUrl, jwt) {
    try {            
      const response = await axios.get(`${apiUrl}/organization?org=${org}&timestamp=${new Date().getTime()}`,
      {
        headers: {
          Authorization: 'Bearer ' + jwt
        }
      });
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      console.log(error);
      return new ResponseWrapper(error.response, error)
    }
  }

  static async loadDoc_isa(apiUrl, jwt) {
    try {            
      const response = await axios.get(`${apiUrl}/document/support`,
      {
        headers: {
          Authorization: 'Bearer ' + jwt
        }
      });
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      console.log(error);
      return new ResponseWrapper(error.response, error)
    }
  }

  static async loadDoc_invoice(apiUrl, jwt) {
    try {            
      const response = await axios.get(`${apiUrl}/document/invoices`,
      {
        headers: {
          Authorization: 'Bearer ' + jwt
        }
      });
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      console.log(error);
      return new ResponseWrapper(error.response, error)
    }
  }

  static async loadDoc_management(apiUrl, jwt) {
    try {            
      const response = await axios.get(`${apiUrl}/document/management`,
      {
        headers: {
          Authorization: 'Bearer ' + jwt
        }
      });
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      console.log(error);
      return new ResponseWrapper(error.response, error)
    }
  }

  static async downloadDoc_isa(apiUrl, jwt, name) {
    try {                  
      name = name.replace(/\//g,'$');
      console.log(name);
      const response = await axios.get(`${apiUrl}/document/support/${name}`,
      {
        responseType: 'arraybuffer',
        headers: {
          Authorization: 'Bearer ' + jwt
        }
      });
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      console.log(error);
      return new ResponseWrapper(error.response, error)
    }
  }
  
  static async getFalls(apiUrl, jwt, perPage=400, page=0, org = '') {
    try {
      const response = await axios.get(`${apiUrl}/report?perPage=${perPage}&timestamp=${new Date().getTime()}&page=${page}${org ? ('&org=' + org) : ''}`,
      {
        headers: {
          Authorization: 'Bearer ' + jwt
        }
      });
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      console.log(error);
      return new ResponseWrapper(error.response, error)
    }
  }

  static async downloadReport(apiUrl, jwt, rid) {
    try {            
      const response = await axios.get(`${apiUrl}/report/download/${rid}`,
      {
        responseType: 'arraybuffer',
        headers: {
          Authorization: 'Bearer ' + jwt
        }
      });      
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      console.log(error);
      return new ResponseWrapper(error.response, error)
    }
  }

  static async getVideoUrl(apiUrl, jwt, captureId) {
  try {            
    const response = await axios.get(`${apiUrl}/report/videourl/${captureId}?timestamp=${new Date().getTime()}`,
    {      
      headers: {
        Authorization: 'Bearer ' + jwt
      }
    });      
    return new ResponseWrapper(response, response.data.data)
  } catch (error) {
    console.log(error);
    return new ResponseWrapper(error.response, error)
  }
}

static async getVideoInfo(apiUrl, jwt, captureId) {
  try {            
    const response = await axios.get(`${apiUrl}/report/videoinfo/${captureId}?timestamp=${new Date().getTime()}`,
    {      
      headers: {
        Authorization: 'Bearer ' + jwt
      }
    });      
    return new ResponseWrapper(response, response.data.data)
  } catch (error) {
    console.log(error);
    return new ResponseWrapper(error.response, error)
  }
}


  static async loadUsers (apiUrl, jwt) {
    try {            
      console.log(apiUrl)
      const response = await axios.get(`${apiUrl}/user?timestamp=${new Date().getTime()}`,
      {
        headers: {
          Authorization: 'Bearer ' + jwt
        }
      });            
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      console.log(error);
      return new ResponseWrapper(error.response, error)
    }
  }

  
  static async askPreview(apiUrl, jwt, roomOid) {
    try {     
    const response = await axios.post(`${apiUrl}/room/${roomOid}/ask-preview`,
    null, {
      headers: {
        Authorization: 'Bearer ' + jwt
      }
    });    
    return new ResponseWrapper(response, response.data.data)
  } catch (error) {
    console.log(error);
    return new ResponseWrapper(error.response, error)
  }
  }

  static async loadImage(apiUrl, jwt, imageOid) {
    console.log(imageOid)
    try {     
    const response = await axios.get(`${apiUrl}/image/${imageOid}`,{
      timeout: 15000,
      headers: {
        Authorization: 'Bearer ' + jwt
      }
    });    
    return new ResponseWrapper(response, response.data.data)
  } catch (error) {
    console.log(error);
    return new ResponseWrapper(error.response, error)
  }
  }

  static async takeInCharge(apiUrl, jwt, roomOid) {    
    try {     
    const response = await axios.post(`${apiUrl}/room/${roomOid}/take-in-charge`,{}, {
      headers: {
        Authorization: 'Bearer ' + jwt
      }
    });    
    return new ResponseWrapper(response, response.data.data)
  } catch (error) {
    console.log(error);
    return new ResponseWrapper(error.response, error)
  }
  }

  static async endAlert(apiUrl, jwt, roomOid) {    
    try {     
    const response = await axios.post(`${apiUrl}/room/${roomOid}/end-alert`,{}, {
      headers: {
        Authorization: 'Bearer ' + jwt
      }
    });    
    return new ResponseWrapper(response, response.data.data)
  } catch (error) {
    console.log(error);
    return new ResponseWrapper(error.response, error)
  }
  }

  static async contactMe(apiUrl, jwt, data) {    
    try {     
    const response = await axios.post(`${apiUrl}/report/upsell`, data, {
      headers: {
        Authorization: 'Bearer ' + jwt
      }
    });    
    return new ResponseWrapper(response, response.data.data)
  } catch (error) {
    console.log(error);
    return new ResponseWrapper(error.response, error)
  }
  }

  static async enableSensor(apiUrl, jwt, roomOid) {    
    try {     
    const response = await axios.post(`${apiUrl}/room/${roomOid}/sensor/settings/enabled-on`,{}, {
      headers: {
        Authorization: 'Bearer ' + jwt
      }
    });    
    return new ResponseWrapper(response, response.data.data)
  } catch (error) {
    console.log(error);
    return new ResponseWrapper(error.response, error)
  }
  }



  static async disableSensor(apiUrl, jwt, roomOid) {    
    try {     
      const response = await axios.post(`${apiUrl}/room/${roomOid}/sensor/settings/enabled-off`,{}, {
        headers: {
          Authorization: 'Bearer ' + jwt
        }
      });    
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      console.log(error);
      return new ResponseWrapper(error.response, error)
    }
  }


  static async enableCloseFollowUp(apiUrl, jwt, roomOid) {
    try {     
      const response = await axios.post(`${apiUrl}/room/${roomOid}/sensor/settings/enable-follow-up-on`,{}, {
        headers: {
          Authorization: 'Bearer ' + jwt
        }
      });    
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      console.log(error);
      return new ResponseWrapper(error.response, error)
    }
  }

  static async disableCloseFollowUp(apiUrl, jwt, roomOid) {
    try {     
      const response = await axios.post(`${apiUrl}/room/${roomOid}/sensor/settings/enable-follow-up-off`,{}, {
        headers: {
          Authorization: 'Bearer ' + jwt
        }
      });    
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      console.log(error);
      return new ResponseWrapper(error.response, error)
    }
  }

  static async enableBedExit(apiUrl, jwt, pl) {
    try {     
      debugger
      const response = await axios.post(`${apiUrl}/room/${pl.oid}/sensor/settings/enable-bed-exit-detection-on`,{ bedExitDetectionType: pl.behack}, {
        headers: {
          Authorization: 'Bearer ' + jwt
        }
      });    
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      console.log(error);
      return new ResponseWrapper(error.response, error)
    }
  }

  static async disableBedExit(apiUrl, jwt, roomOid) {    
    try {     
      const response = await axios.post(`${apiUrl}/room/${roomOid}/sensor/settings/enable-bed-exit-detection-off`,{}, {
        headers: {
          Authorization: 'Bearer ' + jwt
        }
      });    
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      console.log(error);
      return new ResponseWrapper(error.response, error)
    }
  }

  static async setBedExitSettings(apiUrl, jwt, roomOid, settings) {
    try {     
      const response = await axios.post(`${apiUrl}/room/${roomOid}/sensor/settings/bed-exit-time-ranges`,settings, {
        headers: {
          Authorization: 'Bearer ' + jwt
        }
      });    
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      console.log(error);
      return new ResponseWrapper(error.response, error)
    }
  }
  


  static async getReport(apiUrl, jwt, organizationLabel, since) {  
    SPService.bounce('getReport', 5000);  
    try {     
      const response = await axios.get(`${apiUrl}/report?${since ? 'since=' + since + '&': ''}${organizationLabel ? 'org=' + organizationLabel + '&': ''}timestamp=${new Date().getTime()}`,{
        headers: {
          Authorization: 'Bearer ' + jwt
        }
      });    
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      console.log(error);
      return new ResponseWrapper(error.response, error)
    }
  }

  

  static async loadCQS(apiUrl, jwt, organizationLabel) {    
    try {     
      const response = await axios.get(`${apiUrl}/cqs/${organizationLabel}?timestamp=${new Date().getTime()}`,{
        headers: {
          Authorization: 'Bearer ' + jwt
        }
      });    
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      console.log(error);
      return new ResponseWrapper(error.response, error)
    }
  }

  static async createCQS(apiUrl, jwt, cqs) {    
    try {     
      const response = await axios.put(`${apiUrl}/cqs`,cqs, {
        headers: {
          Authorization: 'Bearer ' + jwt
        }
      });    
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      console.log(error);
      return new ResponseWrapper(error.response, error)
    }
  }

  static async updateCQS(apiUrl, jwt, cqs) {    
    try {     
      const response = await axios.post(`${apiUrl}/cqs`,cqs, {
        headers: {
          Authorization: 'Bearer ' + jwt
        }
      });    
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      console.log(error);
      return new ResponseWrapper(error.response, error)
    }
  }

  static async deleteCQS(apiUrl, jwt, oid) {    
    try {     
      const response = await axios.delete(`${apiUrl}/cqs/${oid}`,{
        headers: {
          Authorization: 'Bearer ' + jwt
        }
      });    
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      console.log(error);
      return new ResponseWrapper(error.response, error)
    }
  }
  

  static async getReportInfra(apiUrl, jwt, org) {    
    SPService.bounce('getReportInfra', 5000);
    try {     
      const response = await axios.get(`${apiUrl}/report/infra?org=${org}&timestamp=${new Date().getTime()}`,{
        headers: {
          Authorization: 'Bearer ' + jwt
        }
      });    
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      console.log(error);
      return new ResponseWrapper(error.response, error)
    }
  }


  static async updateUser(apiUrl, jwt, user) {
    /*
    {
      "oid": "string",
      "username": "string",
      "password": "string",
      "email": "string",
      "phone": "string",
      "isCall": true,
      "firstName": "string",
      "lastName": "string",
      "role": 0,
      "needNewPassword": true,
      "organizationLabel": "string",
      "config": 

      {
          "preferredLanguage": "string",
          "enableFallNotification": true,
          "enableBedExitNotification": true
      }

  }
    */
   try {     
    const response = await axios.post(`${apiUrl}/user`,
    user,
    {
      headers: {
        Authorization: 'Bearer ' + jwt
      }
    });    
    return new ResponseWrapper(response, response.data.data)
  } catch (error) {
    console.log(error);
    return new ResponseWrapper(error.response, error)
  }
  }

  static async createUser(apiUrl, jwt, user) {    
   try {     
    const response = await axios.put(`${apiUrl}/user`,
    user,
    {
      headers: {
        Authorization: 'Bearer ' + jwt
      }
    });    
    return new ResponseWrapper(response, response.data.data)
  } catch (error) {
    console.log(error);
    return new ResponseWrapper(error.response, error)
  }
  }

  static async createInvitation(apiUrl, jwt, user)  {
    try {     
      const response = await axios.put(`${apiUrl}/user/invitation`,
      user,
      {
        headers: {
          Authorization: 'Bearer ' + jwt
        }
      });    
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      console.log(error);
      return new ResponseWrapper(error.response, error)
    }
  }

  static async loadInvitations(apiUrl, jwt)  {
    try {     
      const response = await axios.get(`${apiUrl}/user/invitation?timestamp=${new Date().getTime()}`,
      {
        headers: {
          Authorization: 'Bearer ' + jwt
        }
      });    
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      console.log(error);
      return new ResponseWrapper(error.response, error)
    }
  }

  static async addUserToRoomset(apiUrl, jwt, userOid, roomset) {
    // be sure not to creates a doublon
    let auth =  roomset.authorizedUserOIDList.filter(u => u != userOid);
    auth.push(userOid);
    let msg = {
      oid: roomset.oid,
      authorizedUserOIDList: auth
    }

    try {     
      const response = await axios.post(`${apiUrl}/roomset`,
      msg,
      {
        headers: {
          Authorization: 'Bearer ' + jwt
        }
      });    
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      console.log(error);
      return new ResponseWrapper(error.response, error)
    }

    
  }

  static async removeUserFromRoomset(apiUrl, jwt, userOid, roomset) {
    let msg = {
      oid: roomset.oid,
      authorizedUserOIDList: roomset.authorizedUserOIDList.filter(u => u != userOid)
    }
    try {     
      const response = await axios.post(`${apiUrl}/roomset`,
      msg,
      {
        headers: {
          Authorization: 'Bearer ' + jwt
        }
      });    
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      console.log(error);
      return new ResponseWrapper(error.response, error)
    }

    
  }

  static async deleteUser(apiUrl, jwt, userOID) {
        
   try {     
    const response = await axios.delete(`${apiUrl}/user/${userOID}`,    
    {
      headers: {
        Authorization: 'Bearer ' + jwt
      }
    });    
    return new ResponseWrapper(response, response.data.data)
  } catch (error) {
    console.log(error);
    return new ResponseWrapper(error.response, error)
  }
  }


  static async createRoomset(apiUrl, jwt, roomset) {    
    try {     
     const response = await axios.put(`${apiUrl}/roomset`,
     roomset,
     {
       headers: {
         Authorization: 'Bearer ' + jwt
       }
     });    
     return new ResponseWrapper(response, response.data.data)
   } catch (error) {
     console.log(error);
     return new ResponseWrapper(error.response, error)
   }
  }

  static async deleteRoomset(apiUrl, jwt, roomsetOID) {    
    try {     
      console.log('deleteRoomset')
      console.log(jwt);
      debugger
     const response = await axios.delete(`${apiUrl}/roomset/${roomsetOID}`,   
     {
       headers: {
         Authorization: 'Bearer ' + jwt
       }
     });    
     return new ResponseWrapper(response, response.data.data)
   } catch (error) {
     console.log(error);
     debugger
     return new ResponseWrapper(error.response, error)
   }
   }

  static async updateRoomset(apiUrl, jwt, roomset) {    
   try {     
    delete roomset.licensing; // never update licensing
    const response = await axios.post(`${apiUrl}/roomset`,
    roomset,
    {
      headers: {
        Authorization: 'Bearer ' + jwt
      }
    });    
    return new ResponseWrapper(response, response.data.data)
  } catch (error) {
    console.log(error);
    return new ResponseWrapper(error.response, error)
  }
  }

  static async updateRoom(apiUrl, jwt, room) {    
    try {     
     const response = await axios.post(`${apiUrl}/room`,
     room,
     {
       headers: {
         Authorization: 'Bearer ' + jwt
       }
     });     
     return new ResponseWrapper(response, response.data.data)
   } catch (error) {
     console.log(error);
     return new ResponseWrapper(error.response, error)
   }
   }

  static async encrypt(apiUrl, jwt, msg) {    
    try {     
     const response = await axios.post(`${apiUrl}/qrcode/encrypt`,
     { message: msg },
     {
       headers: {
         Authorization: 'Bearer ' + jwt
       }
     });     
     return new ResponseWrapper(response, response.data.data)
   } catch (error) {
     console.log(error);
     return new ResponseWrapper(error.response, error)
   }
   }

   static async loadPAdmissions(apiUrl, jwt, organizationLabel)  {
    try {     
      const response = await axios.get(`${apiUrl}/padmission${organizationLabel ? '/'+organizationLabel : ''}?timestamp=${new Date().getTime()}`,
      {
        headers: {
          Authorization: 'Bearer ' + jwt
        }
      });    
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      console.log(error);
      return new ResponseWrapper(error.response, error)
    }
  }
   
}
