import moment from 'moment';

export default {
    roomsetList: [],
    organizationList: [],
    visibility: {},
    visibilityC: 0,
    users: [],
    invitations: [],
    currentOrganization: null,
    currentLicensing: null,
    organizationInfo: null,
    watching: false,
    watchingOid: null,
    watchingLabel: null,
    watchingImgLoaded: null,
    watchingImgToLoad: false,
    watchingImgToLoadOid: '',
    watchingVideo: false,
    watchingVideoOid: null,
    watchingVideoAlert: null,
    watchingNotificationHistory: false,
    watchingNotificationHistoryData: {},
    watchingSensorConnectivityHistory: false,
    watchingSensorConnectivityHistoryData: {},
    cronSecond: false,
    cronMinute: false,
    multipleRoomsetVisible: true,
    roomWidth: 4,
    sseState: false,
    sseStateChangeDate: null,
    cqs: [],
    /***************************************************/
    /*   Reporting **/
    welcome: {
        userCount: -1,
        phoneCount: -1,
    },
    fallList: [],
    alertList: [],
    infraList: [],
    filter: {
        timeRangeChoice: 0,
        dateFilter: {
            start: moment().add(-3, "months").toISOString(),
            end: moment().toISOString(),
        },

        showFall: true,
        showBedExit: true,
        roomFilterValues: [],
        showND: true,
        showFA: false,
        showTD: true,
        showUN: true,
        showTEST: false,
    },

    filteredAlertList: [],
    alertListPerMonthSummary: null,
    alertListLastMonthSummary: null,
    alertListPerMonthSummaryOrganization: null,
    filteredStats: {
        fallEstimatedCount: '?',
        bedExitAlertCount: '?',
        fallAlertCount: '?',
        falseAlertsCount: '?',
        callsCount: '?',
        callsResponseTime: {
            average: '?',
        },
        alertingResponseTime: {
            average: '?',
        },
        callsUnansweredCount: '?',
    },
    roomSummary: [],
    error: '',
    updatingStat: false,
    reloadSSE: 0, // to force reloading sse
    hasAlert: false,

    pAdmissions: [],
}
