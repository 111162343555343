import VuexPersistence from 'vuex-persist';
import Crypto from 'crypto-js';
import Cookie from 'js-cookie';
import { v4 } from 'uuid';

const cookieName = 'ISAWEBAPPCK';

const storageKeyU = 'ISAWEBAPPU';
const storageKeyF = 'ISAWEBAPPF';

// Get the encryption token from cookie or generate a new one.
const encryptionToken = '71f0f0f3-1ce3-4700-b04b-16118eb46d7f'; //Cookie.get(cookieName) || v4();
const encrypt = false;

// Store the encryption token in a secure cookie.
Cookie.set(cookieName, encryptionToken, { secure: true, expires: 5000 });

const goodMutations = [
  'user/SET_CURRENT_USER',  
  'fleet/SET_SP_UDATE',
  'user/SET_KIOSK_USER',
  'user/SET_USER_PATTERN',
  'user/SET_USER_PASSWORD',
  'user/SET_DEVICEISKIOSK',
  'fleet/SET_SSE_ALL',
  'fleet/SET_CURRENT_ORGANIZATION',
  'user/RESET_USER_CACHE',
  'fleet/RESET_DATA_CACHE',
]

const persistingFleetProperties = [  
  'organizationList',
  'users',
  'invitations',
  'currentOrganization',
  'currentLicensing',
  'organizationInfo',
  'cqs',
  'welcome',
  'filteredStats',
  'alertList',
  'kioskable',
]

function replacer(key, value) {    
   if(persistingFleetProperties.includes(key)) return value;
   else return undefined;
}


const vuexLocal = new VuexPersistence({  
  storage: {    
    getItem: () => {
      // Get the store from local storage.
      if(!encrypt)
      {
        let user = {}
        let fleet = {}
        try
        {
          user = JSON.parse(window.localStorage.getItem(storageKeyU));                   
        } catch(e) {
          console.warn('remove local storage because of ' + e.message)
            window.localStorage.removeItem(storageKeyU);
        }
        try
        {          
          fleet = JSON.parse(window.localStorage.getItem(storageKeyF));          
        } catch(e) {
          console.warn('remove local storage because of ' + e.message)
            window.localStorage.removeItem(storageKeyF);
        }
        if(!user) user = {}
        if(!fleet) fleet = {}
        return { user, fleet}
        
      } else
      {
        const store = window.localStorage.getItem(storageKey);

        if (store) {
          try {
            // Decrypt the store retrieved from local storage
            // using our encryption token stored in cookies.
            console.log('about to decrypt ' + store);
            console.log('with key ' + encryptionToken);
            const bytes = Crypto.AES.decrypt(store, encryptionToken);
  
            return JSON.parse(bytes.toString(Crypto.enc.Utf8));
          } catch (e) {
            // The store will be reset if decryption fails.
            console.warn('remove local storage because of ' + e.message)
            window.localStorage.removeItem(storageKey);
          }
        }
  
      }
      
      return null;
    },
    setItem: (key, value) => {
        if(!encrypt)
        {                        
            const { user , fleet } = JSON.parse(value);
            window.localStorage.setItem(storageKeyF, JSON.stringify(fleet,replacer));
            return window.localStorage.setItem(storageKeyU, JSON.stringify(user));
        } else
        {
            // Encrypt the store using our encryption token stored in cookies.
            const store = Crypto.AES.encrypt(value, encryptionToken).toString();

            // Save the encrypted store in local storage.
            return window.localStorage.setItem(storageKey, store);
        }      
    },
    removeItem: () => window.localStorage.removeItem(storageKey),
  },
  filter: (mutation) => {
    console.log('mutation:' + mutation.type)
    return (goodMutations.indexOf(mutation.type) !== -1) // Boolean
  }
});

export default vuexLocal.plugin;