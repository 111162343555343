<template>
   <div @>    
    <router-view />
  </div>
</template>


<script>

export default {
  data() {
    return {};
  },

  computed: {    
    themeName() {
      return " ";
    },
    rtl() {
      return  " ";
    }
  },
  mounted() {        
  },
  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: "MyISA",
      // all titles will be injected into this template
      titleTemplate: "%s | MintT - MyISA",
      bodyAttrs: {
        class: [this.themeName, "text-left"]
      },
      htmlAttrs: {
        dir: this.rtl,
        lang: this.$i18n.locale,
      }
    };
  }
};
</script>

<style>
.col-form-label {
    padding-top: 0;
}

.table-striped tbody tr:nth-of-type(odd){
   background-color:#75b0a2c0;
}


.dropdown-toggle-no-caret {
  padding-top: 0px !important;
  top: 0px;
}

body {
  
}

</style>


