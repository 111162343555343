


export default {
  currentUser: {
    oid: '',
    role: '',
    username: '',
    email: '',
    jwt: '',        
    needNewPassword: false,
    organizationLabel: '',    
  },
  knownUsers: [],
  kioskUser: {
    username: '',    
    jwt: '',  
    id: '',
    organizationLabel: '',
  },
  isMobile: false,
  deviceIsKiosk: false,    
  needsLogin: false,   // if set to yes, login modal will be shown
  needsLoginData: null,
  needsKioskLogin: false, // if set to yes, login modal will be shown to log as kiosk
  needsForgot: false,
  needsPatternSave: false, // if set to yes, pattern saving modal will be shown
  needsChangePassword: false, // if set to yes, pattern saving modal will be shown    
  needsChangePattern: false, // if set to yes, change pattern modal will be shown
  
  kioskable: false,

  showBedExitSettings: false,
  showBedExitSettingsRoom: null,

  roomEditorShow: false,
  roomEditorData: null,

  sendInvitationShow: false,
  pendingInvitationShow: false,

  userEditorShow: false,
  userEditorData: null,
  userDeletorShow: false,
  userDeletorData: null,  
  notificationEditorShow: false,
  notificationEditorData: null,
  notificationDeletorShow: false,
  notificationDeletorData: null,
  roomsetDeletorShow: false,
  roomsetDeletorData: null,

  cqsEditorShow: false,
  cqsEditorData: null,
  cqsDeletorShow: false,
  cqsDeletorData: null,

  kioskMode: true,
  kioskPage: true,
  
  pageTitle: '',
  
  // Suport modal page enabled and topic
  needsHelp: false,
  needsHelpTopic: null,  


  beforeSignInUrl: null,
  showAbout: false,

  userguidePage: null,
}
