// import "babel-polyfill";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
// import VueRouter from "vue-router";
import GullKit from "./plugins/gull.kit";
// import "babel-polyfill";
// import es6Promise from "es6-promise";
// es6Promise.polyfill();
import store from "./store";
import i18n from "./lang/lang";
import DateRangePicker from 'vue2-daterange-picker'
//you need to import the CSS manually (in case you want to override it)
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
//import material-icon scss
import "font-awesome/css/font-awesome.min.css";
import VueSSE from 'vue-sse';

import Vue2TouchEvents from 'vue2-touch-events'
import vSelect from "vue-select";
import "text-security/text-security.css"
Vue.component("v-select", vSelect);
import "vue-select/src/scss/vue-select.scss";

Vue.use(Vue2TouchEvents, {
  disableClick: false,
  touchClass: '',
  tapTolerance: 10,
  touchHoldTolerance: 400,
  swipeTolerance: 30,
  longTapTimeInterval: 3000,
  namespace: 'touch'
})

import VueClipboard from 'vue-clipboard2'
VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)

import VueTreeNavigation from '@/components/utils/vue-tree-navigation/components/TreeNavigation/TreeNavigation';
Vue.component('vue-tree-navigation', VueTreeNavigation);

 
//defined as global component
Vue.use(GullKit);
Vue.component('VueFontawesome', require('vue-fontawesome-icon/VueFontawesome.vue').default);

// Vue.use(VueRouter);
 
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret, faEye, faPowerOff, faProcedures, faDoorClosed, faDoorOpen, faQuestionCircle
    , faArrowAltCircleRight, faUserCircle, faLock, faBuilding, faLink, faEyeSlash
    , faFolderOpen, faFolder, faNewspaper, faUsers, faPhoneVolume, faObjectGroup, faEdit, faTrashAlt
    , faList, faFileDownload, faPhotoVideo, faPhone, faLifeRing, faCalendarWeek, faPlus, faCheck, faCheckCircle, faArrowDown, faArrowUp, faAtom
    , faBell, faBellSlash, faPlug, faSitemap, faRecycle, faBalanceScale
    , faWifi, faGripLinesVertical, faUserClock, faExclamation, faExclamationCircle, faCompressAlt, faWrench, faKey, faEllipsisV, faUserSlash, faBullhorn
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faBullhorn)
library.add(faEllipsisV)
library.add(faKey)
library.add(faUserSlash)
library.add(faRecycle)
library.add(faWrench)
library.add(faCompressAlt)
library.add(faExclamationCircle)
library.add(faExclamation)
library.add(faUserClock)
library.add(faBalanceScale)
library.add(faUserSecret)
library.add(faEye)
library.add(faPowerOff)
library.add(faProcedures)
library.add(faDoorClosed)
library.add(faDoorOpen)
library.add(faQuestionCircle)
library.add(faArrowAltCircleRight)
library.add(faUserCircle);
library.add(faLock)
library.add(faBuilding)
library.add(faLink)
library.add(faEyeSlash)
library.add(faNewspaper)
library.add(faUsers)
library.add(faPhoneVolume)
library.add(faObjectGroup)
library.add(faEdit)
library.add(faTrashAlt)
library.add(faList)
library.add(faFileDownload)
library.add(faPhotoVideo)
library.add(faPhone)
library.add(faLifeRing)
library.add(faCalendarWeek)
library.add(faPlus)
library.add(faArrowDown)
library.add(faArrowUp)
library.add(faCheck)
library.add(faCheckCircle)
library.add(faBell)
library.add(faBellSlash)
library.add(faAtom)
library.add(faPlug)
library.add(faSitemap)
library.add(faWifi)
library.add(faGripLinesVertical);


Vue.component('font-awesome-icon', FontAwesomeIcon)

// promisified dialogs component
import VuejsDialog from 'vuejs-dialog';
import 'vuejs-dialog/dist/vuejs-dialog-mixin.min.js'; // only needed in custom components

Vue.use(VuejsDialog);
Vue.use(VueSSE);

import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize
} from "vee-validate";
import en from "vee-validate/dist/locale/en.json";
import nl from "vee-validate/dist/locale/nl.json";
import fr from "vee-validate/dist/locale/fr.json";
import * as rules from "vee-validate/dist/rules";

// Install VeeValidate rules and localization
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

localize({
  en,
  fr,
  nl
});

 // Install VeeValidate components globally
 Vue.component("ValidationObserver", ValidationObserver);
 Vue.component("ValidationProvider", ValidationProvider);

import FlagIcon from 'vue-flag-icon'
Vue.use(FlagIcon);

import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';

Vue.use(VueTelInput);

Vue.config.productionTip = false;

const app = new Vue({
  store,
  router,
  i18n,
  render: h => h(App),
  methods: {
    init: async () => {         
     
    }
  }, 
});
app.$mount("#app");

let missingTranslations = {};
let missed = 0;
i18n.missing = (locale, key, vm, values) => {
  missingTranslations[key] = key;
  missed++;
  return key; //'NOT_TRANSLATED'+missed;

}


const initOneSignal = async () => {
  if(window.plugins && window.plugins.OneSignal) {
  // we wait for cordova to init    
  } else if (window.OneSignal) {    
  //  app.init()           
  }
}



document.addEventListener('deviceready', () => {  
 // app.init()      
});


try {
  // Add event listener for electron kiosk login 
  window.api.receiveCredentials((event, args) => {
    store.dispatch("user/loginKioskFromElectron", args);
  });
  
  // Send alive to electron
  window.api.sendAlive();
}
catch(e) {
  console.log('No electron api configuration found');
}

