import { ErrorWrapper, ResponseWrapper } from './util'

export class UsersService { //extends BaseService {

  static async getCurrent () {
    try {      
      const fingerprint = await _getFingerprint()
      const response = await axios.post(`${apiUrl}/user/authenticate`,
        { username, password, organizationLabel })
        


        if (response.data.data.role === 1) // kiosk user
        {
          // save the kiosk user and make it persistent
        } else {

        }
       
      _setAuthData({
        accessToken: response.data.data.jwt,
        exp: response.data.data.expiresAt
      })
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }
}
