export default {
  SET_CURRENT_USER (state, currentUserData) {
    state.currentUser = currentUserData
    state.apiUrl = currentUserData.apiUrl
    if(currentUserData && currentUserData.role === 0)
      state.deviceIsKiosk = true;    
    if(!state.currentOrganization && currentUserData)
      state.currentOrganization = currentUserData.organizationLabel;

    try
    {
      if(window.api && window.api.setCurrentUser) {
        window.api.setCurrentUser({ currentUserData });
      }
    } catch(e) {

    }
  },

  SET_KIOSK_USER (state, currentUserData) {
    console.log('SET_KIOSK_USER called with ')
    console.log(currentUserData);
    if(state.kioskUser.username && !currentUserData.username)
      debugger
    state.kioskUser = currentUserData
    try
    {
      if(window.api && window.api.setKioskUser) {
        window.api.setKioskUser({ currentUserData });
      }
    } catch(e) {

    }
  },
  
  SET_DEVICEISKIOSK(state, value) {
    state.deviceIsKiosk = value;
  },
  SET_ISMOBILE(state, value) {
    state.isMobile = value;
  },

  SET_NEEDS_LOGIN (state, needs) {    
    state.needsLogin = needs
  },
  SET_NEEDS_LOGINDATA(state, needs) {    
    state.needsLoginData = needs
  },

  SET_NEEDS_FORGOT(state, needs) {    
    state.needsForgot = needs
  },
  
  SET_NEEDS_KIOSKLOGIN (state, needs) {    
    state.needsKioskLogin = needs
  },
  SET_NEEDS_LOCKPATTERN(state, { password, enable } ) {
    state.needsPatternSave = enable;
    state.needsPatternSavePwd = password;    
  },
  SET_USER_PATTERN(state, up) {
    state.needsPatternSave = false;
    state.needsPatternSavePwd = null;
    //TODO, save the user pattern
  },

  SET_URLBEFORESIGNIN(state, url) {
    state.beforeSignInUrl = url;    
  },
    
  SET_USER_PASSWORD(state, { user, encryptedPassword, apiUrl}) {
    let ku = state.knownUsers.find(p => p.oid === user.oid)    
    if(!ku) {
      state.knownUsers.push({
        oid: user.oid,
        expiresAt: user.expiresAt,
        role: user.role,
        username: user.username,
        firstname: user.firstname,
        lastname: user.lastname,
        encryptedPassword: encryptedPassword,
        organizationLabel: user.organizationLabel,
        apiUrl: apiUrl,
      })
    } else {
      ku.encryptedPassword = encryptedPassword
    }        
  },
  SET_NEEDS_CHANGEPASSWORD(state, value) {
    state.needsChangePassword = value;
  },

  SET_NEEDS_CHANGEPATTERN(state, value) {
    state.needsChangePattern = value;
  },

  SHOW_USEREDITOR(state, value) {    
    state.userEditorData = value;    
    state.userEditorShow = value != null    
  },
  SHOW_NOTIFICATIONEDITOR(state, value) {
    state.notificationEditorData = value
    state.notificationEditorShow = value != null    
  },

  SHOW_CQS(state, value) {        
    state.cqsEditorData = value;
    state.cqsEditorShow = value != null;    
  },

  SHOW_CQSDELETOR(state, value) {    
    state.cqsDeletorData = value;   
    state.cqsDeletorShow = value != null     
  },

  SHOW_USERDELETOR(state, value) {    
    state.userDeletorData = value;    
    state.userDeletorShow = value != null    
  },
  SHOW_NOTIFICATIONDELETOR(state, value) {        
    state.notificationDeletorData = value;    
    state.notificationDeletorShow = value != null    
  },

  SHOW_ROOMSETDELETOR(state, value) {    
    state.roomsetDeletorData = value;    
    state.roomsetDeletorShow = value != null    
  },

  SHOW_ROOMEDITOR(state, value) {    
    state.roomEditorData = value;    
    state.roomEditorShow = value != null    
  },

  SHOW_BEDEXITSETTINGS(state, value) {    
    console.log('SHOW_BEDEXITSETTINGS set to ' + value != null)
    state.showBedExitSettingsRoom = value;    
    state.showBedExitSettings = value != null    
  },


  NEEDS_HELP(state, value) {    
    state.needsHelpTopic = value
    state.needsHelp = value != null    
  },

  SET_KIOSKMODE(state, value) {
    state.kioskMode = value;
  },
  SET_KIOSKPAGE(state, value) {
    state.kioskPage = value;
  },
  SET_PAGETITLE(state, value) {
    state.pageTitle = value;
  },

  REVOKE_USERPATTERN(state, value) {
    state.knownUsers = state.knownUsers.filter(p => p.oid !== value.oid);
  },

  SHOW_SENDINVITATION(state, value) {
    state.sendInvitationShow = value;
  },

  SHOW_PENDINGINVITATION(state, value) {
    state.pendingInvitationShow = value;
  },

  SET_SHOW_ABOUT(state, value) {
    state.showAbout = value;
  },

  RESET_USER_CACHE(state) {      
    state.currentUser = {
      oid: '',
      role: '',
      username: '',
      email: '',
      jwt: '',        
      needNewPassword: false,
      organizationLabel: '',    
    },
    state.knownUsers= [],
    state.kioskUser= {
      username: '',    
      jwt: '',  
      id: '',
      organizationLabel: '',
    };
  },
  SET_USERGUIDE_PAGE(state, value) {
    state.userguidePage = value;
  },

  SET_KIOSKABLE(state, value) {
    state.kioskable = value;
  }


}
