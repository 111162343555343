import { SPService } from '@/services/sp.service'
import moment from "moment";
import statsLite from 'stats-lite';
import { API_URL} from '../../../.env'
// Extract meaningful information from current shortpolling by getting keys and versions of data in current shortpolling

let _lastReportFill = null;

const extractShortPollRequest = (state) => {
  const toRet = {
    user: {
      version: state.user ? state.user.version : 0,
    },
    roomsetList: [],
    augmented: state.roomsetList.length === 0
  };
  for(const aRoomsetList of state.roomsetList) {
    const trs = {
      oid: aRoomsetList.oid,
      version: aRoomsetList.version,
      roomList: []
    }        
    for(const aRoom of aRoomsetList.roomList) {
      const tr = {
        oid: aRoom.oid,
        version: aRoom.version,
        admissionList: []
      }
      for(const anAdmission of aRoom.admissionList) {
        const ta = {
          id: anAdmission.id,
          version: anAdmission.version,            
        }
        if(anAdmission.alert) {
          ta.alert = {
            id: anAdmission.alert.id,
            version: anAdmission.alert.version,
          }
        }
        tr.admissionList.push(ta);
      }
      trs.roomList.push(tr);
    }
    toRet.roomsetList.push(trs);
  }
  return toRet;
}

const computeAudit = (users, roomsetList, org) => {
  
    if(!users) return;
       const phones = users.filter(u => u.isNotificationUser === true && u.organizationLabel === org)
      const rooms = [];
      for(const rs of roomsetList)
        if(rs.organizationLabel === org && rs.label.charAt(0) !== '_')
          for(const r of rs.roomList) {
              rooms.push({
                oid: r.oid,
                rs: rs,
                r: r,
              })
          }

    // we have phones, we have rooms
    // need to check if for fall and for bedexit
    // a phone is configured for each room      
    const fallCoverage = {};
    const beCoverage = {};
    for(const phone of phones) {
      if(phone.config.enableFallNotification) {
        // first, for falls, let's determine time scope and room scope for this phone
        let phone_timescope = [];
        let phone_roomscope = [];

        if(!phone.config.isFallNotificationScheduled) {
          for(let t = 0; t < 96; t++) { // each 15 min
            phone_timescope.push(t);
          }
        } else { // we have a schedule here
          for(let slot of phone.config.fallNotificationScheduledRanges) {
            let slotstart = Math.floor((slot.startHourLocalTime * 60 + slot.startMinuteLocalTime) / 15);
            let slotend = Math.floor((slot.endHourLocalTime * 60 + slot.endMinuteLocalTime) / 15);
            if(slotend > slotstart)
              for(let t = slotstart; t < slotend; t++) { // each 15 min
                phone_timescope.push(t);
              }
            else 
            {
              for(let t = 0; t < slotend; t++) { // each 15 min
                phone_timescope.push(t);
              }
              for(let t = slotstart; t < 96; t++) { // each 15 min
                phone_timescope.push(t);
              }
            }
            
          }
        }

        if(!phone.config.isNotificationSourceCustomRoomList) {
           // we cover every time for every room allowed
            for(const fc of rooms.filter(room => room.rs.authorizedUserOIDList.find(o => o === phone.oid) != null)) {                            
              phone_roomscope.push(fc); 
          }
        } else {
          for(const fcoid of phone.config.notificationSourceCustomRoomList) {
            let fs = rooms.find(r => r.oid === fcoid);
            if(fs)
              phone_roomscope.push(fs);
          }
        }
        for(const t of phone_timescope)
          for(const r of phone_roomscope) {
            const coverKey = r.oid + '_' + t;
            fallCoverage[coverKey] = true;
          }
        
        // we know timescope and roomscope for this phone
        // let's build
      }
      if(phone.config.enableBedExitNotification) {
        // first, for falls, let's determine time scope and room scope for this phone
        let phone_timescope = [];
        let phone_roomscope = [];

        if(!phone.config.isBedNotificationScheduled) {
          for(let t = 0; t < 96; t++) { // each 15 min
            phone_timescope.push(t);
          }
        } else { // we have a schedule here
          for(let slot of phone.config.bedNotificationScheduledRanges) {
            let slotstart = Math.floor((slot.startHourLocalTime * 60 + slot.startMinuteLocalTime || 0) / 15);
            let slotend = Math.floor((slot.endHourLocalTime * 60 + (slot.endMinuteLocalTime || 0)) / 15);
            if(slotend > slotstart)
              for(let t = slotstart; t < slotend; t++) { // each 15 min
                phone_timescope.push(t);
              }
            else 
            {
              for(let t = 0; t < slotend; t++) { // each 15 min
                phone_timescope.push(t);
              }
              for(let t = slotstart; t < 96; t++) { // each 15 min
                phone_timescope.push(t);
              }
            }
          }
        }

        if(!phone.config.isNotificationSourceCustomRoomList) {
           // we cover every time for every room allowed
            for(const fc of rooms.filter(room => room.rs.authorizedUserOIDList.find(o => o === phone.oid) != null)) {                            
              phone_roomscope.push(fc); 
          }
        } else {
          for(const fcoid of phone.config.notificationSourceCustomRoomList) {
            let fs = rooms.find(r => r.oid === fcoid);
            if(fs)
              phone_roomscope.push(fs);
          }
        }
        for(const t of phone_timescope)
          for(const r of phone_roomscope) {
            const coverKey = r.oid + '_' + t;
            beCoverage[coverKey] = true;
          }
      }      
    }
    // coverage are evaluated, now let's find where there is no cover
    const uncovered = {};
    for(const r of rooms) {
      for(let t = 0; t < 96; t++) {
        const coverKey = r.oid + '_' + t;        
        if(!fallCoverage[coverKey]) {
          if(!uncovered[r.oid]) uncovered[r.oid] = { type: 'fall', oid: r.oid, label: r.r.label, rslabel: r.rs.label, times_fall: [], times_bed: []}
          uncovered[r.oid].times_fall.push(t)          
        }
        if(!beCoverage[coverKey]) {
          if(!uncovered[r.oid]) uncovered[r.oid] = { type: 'bedexit', oid: r.oid, label: r.r.label, rslabel: r.rs.label,  times_fall: [],times_bed: []}
          uncovered[r.oid].times_bed.push(t)          
        }
      }
    }
  // no group segments
    for(const uk in uncovered) {
        const times = uncovered[uk].times_fall;        
        let segments = [];
        let currentSegment = null;
        for(let _t of times)
        {
          if(!currentSegment) {
            currentSegment = { start: _t, end: _t};
            segments.push(currentSegment);
          }
          else {
            if(_t === currentSegment.end + 1) 
              currentSegment.end = _t;
            else {              
              currentSegment = null;
            }
          }
        }
        
        for(const s of segments) {
            // s.end += 1;
            let sh = Math.floor(Math.max(0,s.start-1) / 4);
            let sm = (Math.max(0,s.start-1) % 4) * 15;
            let eh = Math.floor((s.end+1) / 4);
            let em = ((s.end+1) % 4) * 15;
            
            s.start = ("0" + sh).slice(-2) + ':' + ("0" + sm).slice(-2) 
            s.end = ("0" + eh).slice(-2) + ':' + ("0" + em).slice(-2) 
        }
        uncovered[uk].segments = segments;
            
        
    }
    const result = [];
    for(const u in uncovered) result.push(uncovered[u]);
    
    
    return {
        fallErrors: result.filter(p => p.type === 'fall').length,
        bedexitErrors: result.filter(p => p.type === 'bedexit').length,
        errors: result,
    }
};


const filterItem = (a, filter, currentOrganization) => {
  
  if (currentOrganization === 'mintt' && a.organizationLabel !== currentOrganization)
    return false;
  let inScope = true;
  // check in time

  if (!filter.showND && a.detection === "ND" ) inScope = false;
  if (!filter.showTD && a.detection === "TD") inScope = false;

  if (!filter.showFA && a.detection === "FA") inScope = false;

  if (!filter.showUN && a.detection === "Unconfirmed") inScope = false;  
  if (!filter.showTEST && a.isTest) inScope = false;
  if (filter.showTEST && a.isTest) inScope = true;
  

  let t = moment(a.date);
  let s = moment(filter.dateFilter.start);
  let e = moment(filter.dateFilter.end);
  if (t < s || e < t) inScope = false;

  

  if (filter.roomFilterValues)
    inScope =
      inScope &&
        filter.roomFilterValues.some(item => item.externalID === a.externalID);

  if (!filter.showFall && a.type === 5)
    inScope = false;

  if (!filter.showBedExit && a.type === 4)
    inScope = false;

  if(a.type !== 4 && a.type !== 5) {
    inScope = false;    
  }
    
  return inScope;
};

const filterAlertList = (alerts, filter, currentOrganization) => {
  if(!alerts) return [];  
  return alerts.filter(a => filterItem(a, filter, currentOrganization)).sort((a,b) => new Date(b.date) - new Date(a.date));
}

const analyzetwilio_list = (a) => {
  let childPhones = [];  
  if(a.children) // multiple alerts / phone call cases      
    for(let c of a.children)
    {
      let s = analyzetwilio_list(c);
      childPhones.push(s.falls[0]);
    }            
    const twilio_list = a.twilio_list ? JSON.parse(JSON.stringify(a.twilio_list)).sort((a,b) => moment(a.Timestamp) - moment(b.Timestamp)) : [];
    
    // let initCalls = twilio_list.filter(p => p.CallStatus == "initiated");
    let ringingCalls = twilio_list.filter(p => p.CallStatus == "ringing");
    let firstRinging = ringingCalls[0];
    let progressCalls = twilio_list.filter(p => p.CallStatus == "in-progress");
    let successCalls = twilio_list.filter(p => p.CallStatus == "completed");
    let lastProcessCall = progressCalls.slice(-1)[0];
    let lastSuccessCall = twilio_list.filter(p => p.CallStatus == "completed").slice(-1)[0];

    let noAnsweredCalls = twilio_list.filter(p => p.CallStatus == "no-answer");
    
    const calls = {};
    for(let c of twilio_list) {
      if(!calls[c.Called])   calls[c.Called] = { events: [], state: ''};               
    }

    return {
        alerts: [{
          ringingDelay: firstRinging ? moment(firstRinging.Timestamp) - moment(a.date) : 0,
          phoneAnswerDelay: lastProcessCall ? moment(lastProcessCall.Timestamp) - moment(a.date) : 0,
          completedDelay: lastSuccessCall ? moment(lastSuccessCall.Timestamp) - moment(a.date) : 0,
          activePhoneNumber: Object.keys(calls).length,
          callCount: ringingCalls ? ringingCalls.length : 0,
          successCall: successCalls ? successCalls.length > 0 : 0,
          unanswered: noAnsweredCalls ? noAnsweredCalls.length : 0,
        }, ...childPhones],
        falls: {
          completedDelay: lastSuccessCall ? moment(lastSuccessCall.Timestamp) - moment(a.date) : 0,
          successCall: successCalls ? successCalls.length > 0 : 0,
        }
    };
}

const prepareRoomSummary = (filteredAlerts, roomsetList, currentOrganization) => {
  const roomSummary = {};  
  for(const rs of roomsetList){
    if(rs.organizationLabel === currentOrganization && rs.label.charAt(0) !== '_')
    for(const r of rs.roomList) {
        roomSummary[r.oid] = {
          roomOid: r.oid,
          roomLabel: r.label,
          roomsetLabel: rs.label,
          totalAlerts: 0,
          totalFalls: 0,
          totalBedExit: 0,
          dates: [],
        }
    }
  }

  for(const a of filteredAlerts) {

    const r = roomSummary[a.roomOID];
    if(r) {
      if(a.type === 5)
      {
        r.totalFalls++;
        r.totalAlerts+= a.children ? (a.children.length+1) : 1;
        r.dates.push(moment(a.date));
      } else  if(a.type === 4){
        r.totalBedExit += 1;
      }        
    }
  }
  
  const ks = Object.keys(roomSummary);
  const result = {
    totalFalls: 0,
    totalBedExits: 0,
    totalAlerts: 0,
    rooms: [],
  }  
  for(const k of ks){
    const r = roomSummary[k];
    if(r.dates.length > 1) {
      r.dates = r.dates.sort((a,b) => a - b);
      let diffs = 0;
      let kdiffs = 0;
      
      for(let t = 1; t < r.dates.length; t++)
          {
            let d = r.dates[t].diff(r.dates[t-1],'seconds');
            if(d > 2)
            {
              diffs+= d
              kdiffs++;
            }                
          }
        if(kdiffs > 0) {
          r.fallRate = diffs / kdiffs;

          const tM = (30 * 24 * 60 * 60 );      
          const tD = ( 1 * 24 * 60 * 60 );
          const tH = (1 * 1 * 60 * 60 );
              
  
          const diffM = Math.floor((r.fallRate) / tM);
          const diffD = Math.floor((r.fallRate-diffM*tM) / tD);      
          const diffH = Math.floor((r.fallRate-diffM*tM - diffD*tD) / tH);
          
                  
          r.fallRate =   (diffM > 0? `${diffM} months ` : '')
                              +  (diffD > 0? `${diffD} days ` : '')
                              +  (diffH > 0? `${diffH} hours ` : '');      
                              
          r.fallRateMonths =  diffM;                             
          r.fallRateDays =  diffD;
          r.fallRateHours =  diffH;


        } else 
          r.fallRate = -1;
    }
    result.rooms.push(r);    
  }
  
  return result.rooms;

}

const updateStats = (state) => {
    
    const lfilteredAlertList =  filterAlertList(state.alertList, state.filter, state.currentOrganization);
    const stats = {
      fallEstimatedCount: '?',
      bedExitAlertCount: '?',
      fallAlertCount: '?',
      falseAlertsCount: '?',
      callsCount: 0,  
      callsResponseTime: {
        average: 0,
      },
      callsUnansweredCount: '?',    
    };
    let phoneNumber = 0;
    let calls = [];
    let falls = [];
    let count = 0;
    let countU = 0;
    for(let a of lfilteredAlertList) {
      let phoneStats = analyzetwilio_list(a);
      
      for(let p of phoneStats.alerts) {
        if(p)
        {
          count += p.callCount;
          countU+= p.unanswered;
          if(p.phoneAnswerDelay) calls.push((p.phoneAnswerDelay - p.ringingDelay) / 1000);
        }        
      }
      if(a.type === 5)
        falls.push(phoneStats.falls.completedDelay / 1000)
    }
    stats.callsCount = count;
    stats.callsUnansweredCount = countU;    
    stats.callsResponseTime = {
      average: statsLite.mean(calls),
      median: statsLite.median(calls),
      stddev: statsLite.sampleStdev(calls)
    }
    
    stats.alertingResponseTime = {
      average: statsLite.mean(falls),
      median: statsLite.median(falls),
      stddev: statsLite.sampleStdev(falls)
    }
  
    stats.fallEstimatedCount = lfilteredAlertList.filter(p => p.type === 5 && !p.isTest).length;
    stats.bedExitAlertCount = lfilteredAlertList.filter(p => p.type === 4 && !p.isTest).length;
    stats.fallAlertCount = lfilteredAlertList.filter(p => p.type === 5 && !p.isTest).map(p => p.children ? p.children.length : 1).reduce((a, b) => a + b, 0)
    stats.falseAlertsCount = lfilteredAlertList.filter(p => p.type === 5 && p.validation === 'FA' && !p.isTest).length;
  

    return {
      filteredAlertList: lfilteredAlertList,
      filteredStats: stats,
      roomSummary: prepareRoomSummary(state.alertList.filter(p => moment(p.date).isAfter(moment().add(-6,'month'))), state.roomsetList, state.currentOrganization)    
    }
    
}

let _loadingImage = false;



export default {
  async shortPoll({state,rootState, commit, dispatch}, reset) {
    let jwt = rootState.user.currentUser.jwt || rootState.user.kioskUser.jwt
    let apiUrl = API_URL.url || rootState.user.kioskUser.apiUrl
    
    // if no current user, set kiosk as active current user
    if(!rootState.user.currentUser.jwt && rootState.user.kioskUser.jwt)
    {
      await commit('user/SET_CURRENT_USER', rootState.user.kioskUser, {root: true})      
      await commit('user/SET_WATCHING_IMAGE', null, {root: true});
      await commit('user/SET_WATCHING_NOTIFICATIONHISTORY', null, {root: true});
      await commit('user/SET_WATCHING_ROOM', null, {root: true});

      this.$bvToast.toast( this.$t('You session has expired'), {
        title: this.$t('User Session'),            
        solid: true,
        variant: 'warning',
        duration: 5000
      });

    }
    console.log('force reloadSSE')
    state.reloadSSE = new Date().getTime();
    
    if(false && jwt && apiUrl){      // check SSE
      let st = {
        user: {
          version: state.user ? state.user.version : 0,
        },
        roomsetList: [],
        augmented: true
      };

      if(!reset)
        st = extractShortPollRequest(state);      
      let result = await SPService.shortpoll(apiUrl, jwt,st);            
      if(result && result.status === 200) {
        commit('SET_SP_UPDATE', { newState : result.data, isUpdate: !st.augmented })
      } else if(result && result.status === 204) {
        // nothing to change
      } else  if(result && result.status === 400) { // jwt expired, logout
        console.log('session expired');        
        await dispatch('user/logout', null, {root: true})
        
      }
    } else {
   //   console.warn('short polled cancelled, no token found')
    }
    
  },

  async checkauth({ state, rootstate, commit, dispatch}) {
    
  },

  async shortPollSSE({state,rootState, commit, dispatch}, data) {    
    if(data.type === 'message') {      
      let payload = data.data;         
      console.log(data.type + '/' + payload.type);   
      if(payload.type =='rooms') {
        commit('SET_SSE_ROOM', payload.data);
        if(state.watching &&state.watchingOid === payload.data.oid) // currently watching this room
        {          
          console.log('toLoad: ' + state.watchingImgToLoad)          
          if(state.watchingImgToLoad) {    
            if(state.watchingImgToLoadOid && state.watchingImgToLoadOid != '')
            {
              console.log('load image  from shortPollSSE' + state.watchingImgToLoadOid)                                              
              dispatch('loadImage');              
            }          
          }
        }
      } else if(payload.type =='roomsets') {
        return commit('SET_SSE_ROOMSET', payload.data);
      } else if(payload.type === 'all' && payload.data) {
        return commit('SET_SSE_ALL', payload.data);
      }
    } else if (data.type === 'open') {
      return commit('SET_SSE_STATE', true);
    }
    else if (data.type === 'error' && rootState.fleet.sseState) {
      return commit('SET_SSE_STATE', false);
    }
    else if(data.type === 'purgeData') {
      this.$router.push('/purge/data');
    } else if(data.type === 'purgeUser') {
      this.$router.push('/purge/user');
    } else if (data.type === 'purgeAll') {
      this.$router.push('/purge/all');
    }
    else {
      console.log(data.type);      
    }
    
    if(false && jwt && apiUrl){      // check SSE
      let st = {
        user: {
          version: state.user ? state.user.version : 0,
        },
        roomsetList: [],
        augmented: true
      };

      if(!reset)
        st = extractShortPollRequest(state);      
      let result = await SPService.shortpoll(apiUrl, jwt,st);            
      if(result && result.status === 200) {
        commit('SET_SP_UPDATE', { newState : result.data, isUpdate: !st.augmented })
      } else if(result && result.status === 204) {
        // nothing to change
      } else  if(result && result.status === 400) { // jwt expired, logout
        console.log('session expired');        
        await dispatch('user/logout', null, {root: true})
        
      }
    } else {
   //   console.warn('short polled cancelled, no token found')
    }
    
  },



  async loadUsers({ rootState, commit }) {
    let jwt = rootState.user.currentUser.jwt || rootState.user.kioskUser.jwt
    let apiUrl = API_URL.url || rootState.user.kioskUser.apiUrl

    let res = await SPService.loadUsers(apiUrl, jwt);
    const audit = computeAudit(res.data, rootState.fleet.roomsetList, rootState.fleet.currentOrganization);
    if(res && res.status === 200) {              
      commit('SET_SET_USERS', {users: res.data, audit});
    }      
    
    let res2 = await SPService.loadInvitations(apiUrl, jwt);
    if(res2 && res2.status === 200) {              
      commit('SET_SET_INVITATIONS',  res2.data);
    }      
  },

  async loadCQS({ rootState, commit }) {
    let jwt = rootState.user.currentUser.jwt || rootState.user.kioskUser.jwt
    let apiUrl = API_URL.url || rootState.user.kioskUser.apiUrl

    let res = await SPService.loadCQS(apiUrl, jwt, rootState.fleet.currentOrganization );
    // const audit = computeAudit(res.data, rootState.fleet.roomsetList, rootState.fleet.currentOrganization);
    if(res && res.status === 200) {              
      commit('SET_CQS', res.data );
    }      
   
  },

  async updateUser({ commit}, user) {
    commit('SET_SET_USER', user);
  },

  async updateRoomsetAuthorizedList({ commit}, user) {
    commit('SET_RSAUTH', user);
  },

  async whoami({state,rootState}) {
    let jwt = rootState.user.currentUser.jwt || rootState.user.kioskUser.jwt
    let apiUrl = API_URL.url || rootState.user.kioskUser.apiUrl
           
    if(jwt && apiUrl){    
      let result = await SPService.whoami(apiUrl, jwt);                  
    } else {
      console.warn('short whoami cancelled, no token found')
    }    
  },

  async watchRoom({state,rootState, commit}, watch) {        
    if(watch.args.room.sensor.settings.enableFollowUp)
    {
      commit('SET_WATCHING_ROOM', watch.args)
      let apiUrl = API_URL.url;
      let jwt = rootState.user.currentUser.jwt;

      // check license
      let hasLicense = false;
      for(let rs of state.roomsetList)
        if(rs.organizationLabel === state.currentOrganization && rs.roomList && rs.label.charAt(0) !== '_')
        for(let r of rs.roomList) {
          if(r.oid === state.watchingOid) {
            hasLicense = rs.licensing && rs.licensing.closeFollowUp;
            break;
          }
        }

      if(hasLicense)
        await SPService.askPreview(apiUrl, jwt,watch.oid);    
      return true
    } else 
    {
      return false
    }
  },

  async watchVideo({commit, rootState }, alert) {
    if(!alert)
      return commit('SET_WATCHING_VIDEO', alert);

    let apiUrl = API_URL.url;
    let jwt = rootState.user.currentUser.jwt;
    
    try
    {
      let result = await SPService.getVideoUrl(apiUrl, jwt, alert.video);
      if(alert.children) {
        for(let a of alert.children) {          
          let cresult = await SPService.getVideoUrl(apiUrl, jwt, a.video);
          if(cresult.status === 200) {
            a.videoUrl = cresult.data;
          }
        }
      }

      if (result.status === 200) {
        alert.videoUrl = result.data;
        commit('SET_WATCHING_VIDEO', alert);
      } else {            
          commit('SET_ERROR', 'Your licence level does not allow you to access videos. Please contact your administrator.');          
      }
    } catch(err) {
        commit('SET_ERROR', 'Your licence level does not allow you to access videos. Please contact your administrator.');
    }
    
    
    
  },

  

  async watchNotificationhistory({commit, rootState }, alert) {
    return commit('SET_WATCHING_NOTIFICATIONHISTORY', alert);
    },

  async watchSensorConnectionHistory({commit, rootState }, alert) {
    return commit('SET_WATCHING_SENSORCONNECTIONHISTORY', alert);
  },

  
  async loadImage({state,rootState, commit}) {
    if(!state.watchingImgToLoad && state.watchingImgToLoadOid != '')
      return;
      if(_loadingImage) return;
      _loadingImage = true;
      try
      {
        let apiUrl = API_URL.url;
        let jwt = rootState.user.currentUser.jwt;        
        let result = await SPService.loadImage(apiUrl, jwt,state.watchingImgToLoadOid);
        if(result && result.status === 200 && result.data) {           
          commit('SET_WATCHING_IMAGE', result.data.binary);
          console.log('load image  returns from shortPollSSE' + state.watchingImgToLoadOid) 
          if(state.watchingOid)
          console.log('load image  returns with image from shortPollSSE' + state.watchingImgToLoadOid) 
          SPService.askPreview(API_URL.url, rootState.user.currentUser.jwt, state.watchingOid)
        }
      } catch(err) {

      }
    _loadingImage = false;
  },

  async takeInChargeAlert({ root, rootState, commit, dispatch}, room) {
    let jwt = rootState.user.currentUser.jwt || rootState.user.kioskUser.jwt
    let apiUrl = API_URL.url || rootState.user.kioskUser.apiUrl    
    let result = await SPService.takeInCharge(apiUrl, jwt,room.oid);
    console.log(result)
    dispatch('shortPoll');
  },
  async endAlert({ root, rootState, commit, dispatch}, room) {
    let jwt = rootState.user.currentUser.jwt || rootState.user.kioskUser.jwt
    let apiUrl = API_URL.url || rootState.user.kioskUser.apiUrl    
    let result = await SPService.endAlert(apiUrl, jwt,room.oid);
    console.log(result)
    dispatch('shortPoll');
  },

  async moveSensor({ rootState, dispatch}, args) {
    const room = args.room;
    room.roomsetOID = args.rsoid; 
    if (room.dashboard != null || room.dashboard != 0)
    {
      room.dashboardPosition = args.dashboard;
    }
    let answer = await SPService.updateRoom(API_URL.url, rootState.user.currentUser.jwt, 
      room       
    );
    console.log(answer);
    setTimeout(() => {
      dispatch('shortPoll');
    }, 500);
  },

  async enableSensor({ root, rootState, commit, dispatch}, room) {
    let jwt = rootState.user.currentUser.jwt || rootState.user.kioskUser.jwt
    let apiUrl = API_URL.url || rootState.user.kioskUser.apiUrl    
    
    try
    {
      let result = await SPService.enableSensor(apiUrl, jwt,room.oid);
      if (result.status === 402)
        commit('user/NEEDS_HELP','ISAOutOfLicenseFall', {root: true})
      else if (result.status == 200)
        commit('updateSensorEnabled', room.oid, true)
    } catch (err) {
      commit('user/NEEDS_HELP','ISAOutOfLicenseFall', {root: true})
    }
        
    setTimeout(() => {
      dispatch('shortPoll');
    }, 500);
  },

  async disableSensor({ root, rootState, commit, dispatch}, room) {
    let jwt = rootState.user.currentUser.jwt || rootState.user.kioskUser.jwt
    let apiUrl = API_URL.url || rootState.user.kioskUser.apiUrl    
    try {
      let result = await SPService.disableSensor(apiUrl, jwt,room.oid);
      if (result.status === 402)
        commit('user/NEEDS_HELP','ISAOutOfLicenseBedexit')
      else if (result.status == 200)
        commit('updateSensorEnabled', room.oid, false)
    } catch (err) {
      debugger
      commit('user/NEEDS_HELP','ISAOutOfLicenseBedexit')
    }

    setTimeout(() => {
      dispatch('shortPoll');
    }, 500);
  },

  async enableCloseFollowUp({ root, rootState, commit, dispatch}, roomOID) {
    return new Promise(async (resolve) => {
      let jwt = rootState.user.currentUser.jwt || rootState.user.kioskUser.jwt
      let apiUrl = API_URL.url || rootState.user.kioskUser.apiUrl    
      let result = await SPService.enableCloseFollowUp(apiUrl, jwt,roomOID);    

      setTimeout(async () => {
        await dispatch('shortPoll');
        resolve();
      }, 500);
    });
    
  },

  async disableCloseFollowUp({ root, rootState, commit, dispatch}, roomOID) {
    let jwt = rootState.user.currentUser.jwt || rootState.user.kioskUser.jwt
    let apiUrl = API_URL.url || rootState.user.kioskUser.apiUrl    
    let result = await SPService.disableCloseFollowUp(apiUrl, jwt,roomOID);    
    setTimeout(() => {
      dispatch('shortPoll');
    }, 500);
    
  },

  async enableBedExit({ root, rootState, commit, dispatch}, pl) {
    
    let jwt = rootState.user.currentUser.jwt || rootState.user.kioskUser.jwt
    let apiUrl = API_URL.url || rootState.user.kioskUser.apiUrl    
    

    try
    {
      let result = await SPService.enableBedExit(apiUrl, jwt,pl);
      if (result.status === 402)
        commit('user/NEEDS_HELP','ISAOutOfLicenseBedexit', {root: true})
    } catch (err) {
      debugger
      commit('user/NEEDS_HELP','ISAOutOfLicenseBedexit', {root: true})
    }

    setTimeout(() => {
      dispatch('shortPoll');
    }, 500);
  },

  async disableBedExit({ root, rootState, commit, dispatch}, roomOID, behacked) {
    let jwt = rootState.user.currentUser.jwt || rootState.user.kioskUser.jwt
    let apiUrl = API_URL.url || rootState.user.kioskUser.apiUrl    
    let result = await SPService.disableBedExit(apiUrl, jwt,roomOID, behacked);
    setTimeout(() => {
      dispatch('shortPoll');
    }, 500);
  },

  async setBedExitSettings({ root, rootState, commit, dispatch}, { roomOID, settings }) {
    let jwt = rootState.user.currentUser.jwt || rootState.user.kioskUser.jwt
    let apiUrl = API_URL.url || rootState.user.kioskUser.apiUrl    
    let result = await SPService.setBedExitSettings(apiUrl, jwt,roomOID,settings);
    setTimeout(() => {
      dispatch('shortPoll');
    }, 500);
  },

  async setCurrentOrganization({ commit,state }, currentOrganization) {        
      commit('SET_CURRENT_ORGANIZATION', currentOrganization);    
      commit('SET_UPDATING_STATS', true);
      const stats = updateStats(state);    
      commit('SET_UPDATING_STATS', false);
      commit('SET_REPORT_STATS', stats);   
  },
  async loadOrganizationInfo({ root, rootState, commit, dispatch}) {
    let jwt = rootState.user.currentUser.jwt || rootState.user.kioskUser.jwt
    let apiUrl = API_URL.url || rootState.user.kioskUser.apiUrl    
    let result = await SPService.loadOrganizationInfo(rootState.fleet.currentOrganization, apiUrl, jwt);
    commit('SET_ORGANIZATION_INFO',result.data);    
  },

  /***********************************************************************************************/
  // report stuff
  /***********************************************************************************************/

  async fillReport({state,rootState, commit, dispatch}) {
    let jwt = rootState.user.currentUser.jwt || rootState.user.kioskUser.jwt
    let apiUrl = API_URL.url || rootState.user.kioskUser.apiUrl

    if(jwt && apiUrl){
      
      let offset = 0;
      let since = _lastReportFill;            
      
      let result = await SPService.report(apiUrl, jwt, rootState.fleet.currentOrganization, since, offset)      
      _lastReportFill = Date.now();

      console.log('filling report since ' + _lastReportFill);

      if(result && result.status === 200) {                          
        commit('APPEND_REPORT', { welcome: result.data.welcome, alerts: result.data.contents, currentOrganization: rootState.fleet.currentOrganization, roomsetList: rootState.fleet.roomsetList });
        const stats = updateStats(state);    
        commit('SET_UPDATING_STATS', false);
        commit('SET_REPORT_STATS', stats);  
      } 
      else if(result && result.status === 204) {
      } else  if(result && result.status === 400) { // jwt expired, logout        
      }


      if(state.infraList.length === 0) {
        result = await SPService.getReportInfra(apiUrl, jwt, rootState.fleet.currentOrganization)   
        if(result.data && result.data.contents)     
          commit('APPEND_INFRAREPORT', { infraList: result.data.contents, currentOrganization: rootState.fleet.currentOrganization, roomsetList: rootState.fleet.roomsetList });
      }
      

    } else {
      console.warn('fillReport cancelled, no token found')
    }
  },

  
  async fillReportReload({state,rootState, commit, dispatch}) {
    let jwt = rootState.user.currentUser.jwt || rootState.user.kioskUser.jwt
    let apiUrl = API_URL.url || rootState.user.kioskUser.apiUrl

    if(jwt && apiUrl){           

      let offset = 0;
      let since = _lastReportFill;            

      let result = await SPService.report(apiUrl, jwt, rootState.fleet.currentOrganization, null, 0)          
      
      

      

      if(result && result.status === 200) {                                
        _lastReportFill = Date.now();
        console.log('filling report since ' + _lastReportFill);
        commit('APPEND_REPORT', { welcome: result.data.welcome, alerts: result.data.contents, currentOrganization: rootState.fleet.currentOrganization, roomsetList: rootState.fleet.roomsetList });
        const stats = updateStats(state);    
        commit('SET_UPDATING_STATS', false);
        commit('SET_REPORT_STATS', stats);  
      } 
      else if(result && result.status === 204) {
      } else  if(result && result.status === 400) { // jwt expired, logout        
      }

      result = await SPService.getReportInfra(apiUrl, jwt, rootState.fleet.currentOrganization)    
      if(result.data && result.data.contents)         
        commit('APPEND_INFRAREPORT', { infraList: result.data.contents, currentOrganization: rootState.fleet.currentOrganization, roomsetList: rootState.fleet.roomsetList });

    } else {
      console.warn('fillReport cancelled, no token found')
    }
  },

  async loadPAdmissions({ state, rootState , commit}) {
    let jwt = rootState.user.currentUser.jwt || rootState.user.kioskUser.jwt
    let apiUrl = API_URL.url || rootState.user.kioskUser.apiUrl
    if(jwt && apiUrl){
      console.log('fetch PAdmissions');
            
      let result = await SPService.loadPAdmissions(apiUrl, jwt, rootState.fleet.currentOrganization)
      console.log(result);
      if(result && result.status === 200) {
        commit('SET_PADMISSIONS', result.data);
      } 
      else if(result && result.status === 204) {        
      } else  if(result && result.status === 400) { // jwt expired, logout        
      }
    } else {
      console.warn('loadPAdmissions cancelled, no token found')
    }
  },


  async fetchDownloadableReports({ state, rootState, commit}) {
    let jwt = rootState.user.currentUser.jwt || rootState.user.kioskUser.jwt
    let apiUrl = API_URL.url || rootState.user.kioskUser.apiUrl

    if(jwt && apiUrl){
      console.log('fetch downloadables');
            
      let result = await SPService.loadDoc_reports(apiUrl, jwt, rootState.fleet.currentOrganization)
      console.log(result);
      if(result && result.status === 200) {
        commit('SET_DOWNLOADABLE', result.data);
      } 
      else if(result && result.status === 204) {        
      } else  if(result && result.status === 400) { // jwt expired, logout        
      }
    } else {
      console.warn('fillReport cancelled, no token found')
    }
  },

  async changeFilter({ state, commit}, filter) {
      let start = new Date();
      console.log('applying filter --------------------')
      console.log(filter);
      await commit('SET_UPDATING_STATS', true);
      console.log('changeFilter before SET_REPORT_FILTER -> ' + (new Date() - start) + 'ms' );
      await commit('SET_REPORT_FILTER', filter);
      // update now
      console.log('changeFilter SET_REPORT_FILTER set -> ' + (new Date() - start) + 'ms' );

      
      console.log('changeFilter before computing stats -> ' + (new Date() - start) + 'ms' );
      const stats = updateStats(state);
      console.log('changeFilter computing done -> ' + (new Date() - start) + 'ms' );      
      await  commit('SET_REPORT_STATS', stats);
      console.log('changeFilter SET_REPORT_STATS done -> ' + (new Date() - start) + 'ms' );
      await   commit('SET_UPDATING_STATS', false);
      console.log('changeFilter SET_UPDATING_STATS done -> ' + (new Date() - start) + 'ms' );

  },

  async resetDataCache({ state, commit, dispatch}) {
    await commit('RESET_DATA_CACHE');
    
    await dispatch('shortPoll');
    await dispatch('fillReport');
    await dispatch('loadUsers');
    
  }
  
}
