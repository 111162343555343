import Vuex from "vuex";
import Vue from "vue";


import largeSidebar from "./modules/largeSidebar";
import config from "./modules/config";

/* MintT custom stores */
import user from './modules/user'
import fleet from './modules/fleet'
import securedPersistence from './persistence'


// Load Vuex
Vue.use(Vuex);

// Create store
export default new Vuex.Store({
  modules: {
    largeSidebar,        
    // MintT    
    user,  
    fleet,    
  },
  strict: false, //process.env.NODE_ENV === 'development',
  plugins: [securedPersistence],  
});
