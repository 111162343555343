export const API_PORT = '5000'
//export const API_URL = `http://localhost:3000`

// export const API_URL = { url: `https://openapi2.mintt.cloud` };
export const API_URL = { url: `https://openapi.mintt.cloud` };
export const switchAPI = (n) => { API_URL.url = n; debugger; window.localStorage.setItem('API_URL', n)};

let api_url = window.localStorage.getItem('API_URL');
if(api_url) switchAPI(api_url)


export const DOMAIN_TITLE = 'ISA-dashboard'


